<template>
    <v-dialog class="editor" :retain-focus="false" v-model="value" persistent>
        <main style="max-height:calc(100vh - 200px); overflow:auto;" class="cardbgdark">
            <v-card class="cardbgdark">
                <v-card-title class="text-h5">
                    <v-flex xs12 md2 d-flex>
                        {{ resourceName }}
                    </v-flex>
                    <v-flex xs6 sm4 md2 d-flex v-if="isVoyage === 1">
                        <v-text-field prepend-icon="label" class="mr-4" v-model="code" label="Project code" :rules="[rules.code]">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md2 d-flex v-if="typeof features.trafficArea !== 'undefined' && features.trafficArea === 1">
                        <v-select class="ml-4" v-model="trafficArea" :items="trafficAreas" @change="changeTraffic()" item-key="value" label="Traffic area" />
                    </v-flex>
                    <v-flex xs6 sm4 md2 d-flex>
                        <v-select class="ml-4" v-model="projectTypeId" :items="activeProjectTypes" @change="setProjectType()" item-key="id" item-text="[name]"
                            item-value="id" label="Project type" />
                    </v-flex>
                    <v-flex xs6 sm4 md2 d-flex v-if="isVoyage === 1">
                        <v-select @change="setConfirmedStatus()" class="ml-4"
                            :prepend-icon="status === 2 ? 'check' : status === 1 ? 'emoji_events' : status === 3 ? 'filter_none' : status === 4 ? 'content_paste' : 'emoji_objects'"
                            :color="status === 2 ? 'green' : status === 1 ? 'orange' : status === 3 ? '#777' : status === 4 ? '#777' : 'red'" v-model="status"
                            :items="statusTypes" item-key="value" item-text="[name]" item-value="value" label="Status" />
                    </v-flex>
                    <v-flex xs6 sm4 md2 d-flex v-if="!resource.shipId">
                        <v-select class="ml-4" @change="changeNominatedShip()" v-model="shipIdNominated" :items="extShips" item-key="id" item-text="[name]"
                            item-value="id" label="Nominated ship" />
                    </v-flex>
                    <div style="position: absolute;font-size: 10px;left: 18px;bottom: -4px;" v-show="isVoyage === 1 && voyageTotals && voyageTotals.text">
                        Voyage totals: {{ voyageTotals.text }}
                    </div>
                    <div style="position: absolute;font-size: 10px;right: 18px;bottom: -4px;">
                        {{ eventRecord ? eventRecord.authorName : '' }}
                        {{ eventRecord ? showModifiedTime() : '' }}
                    </div>
                </v-card-title>
            </v-card>
            <v-divider />
            <!-- Project type voyage -->
            <div v-if="isVoyage === 1">
                <v-toolbar>
                    <v-switch class="mx-4" v-model="prevPort" @change="checkPrevPort();etaUpdated=1" :false-value="0" :true-value="1"
                        :label="prevPort === 1 && this.prevPortData ? this.prevPortData.name : 'Off'" color="primary" hint="Previous port" persistent-hint />
                    <v-spacer />
                    <v-switch class="mx-4" v-model="updateFreight" :label="updateFreight ? 'On' : 'Off'" @change="toggleAutoUpdateFreight()" color="primary"
                        hint="Auto freight" persistent-hint />
                    <v-switch class="mx-4" v-model="autoCalcEtdEta" :label="autoCalcEtdEta ? 'On' : 'Off'" color="primary" hint="Auto ETA/ETD" persistent-hint />
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn class="ml-4 mr-1" icon v-on="on">
                                <v-icon @click="reCalculateRoutes()">mdi-reload</v-icon>
                            </v-btn>
                        </template>
                        <span>Recalculate routes</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn class="ml-4 mr-1" icon v-on="on">
                                <v-icon @click="showRouteViewer = true">timeline</v-icon>
                            </v-btn>
                        </template>
                        <span>Show route on map</span>
                    </v-tooltip>
                </v-toolbar>
                <ejs-grid ref="grid" :dataSource="portCalls" :rowHeight="28" :height="portsTableHeight()" :allowRowDragAndDrop="true" :queryCellInfo="customizeCell"
                    :rowDrop="dropEnd" :gridSettings="gridSettings" :rowSelected="rowSelected" :commandClick="commandClick">
                    <e-columns>
                        <e-column field="name" headerText="Port" width="100"></e-column>
                        <e-column field="laycanShort" headerText="Laycan" width="100"></e-column>
                        <e-column field="eta" headerText="ETA" width="110" format="d/M/y HH:mm" type="date"></e-column>
                        <e-column field="portHours" headerText="Port hours" width="80"></e-column>
                        <e-column field="etd" headerText="ETD" width="110" format="d/M/y HH:mm" type="date"></e-column>
                        <e-column field="typeName" headerText="Type" width="100"></e-column>
                        <e-column field="seaText" headerText="At sea" width="100"></e-column>
                        <e-column field="speed" headerText="Speed" width="80"></e-column>
                        <e-column field="etaUpdate" headerText="ETA update" textAlign="center" width="50" :commands="commands"></e-column>
                    </e-columns>
                </ejs-grid>
                <v-toolbar class="cardbgdark" dense>
                    Add port
                    <v-autocomplete v-model="newPort" @input="addPort(newPort, null)" :loading="portLoading" :items="ports" :item-text="portItemText" item-key="id"
                        :search-input.sync="portSearch" cache-items return-object class="mx-4" text hide-no-data hide-details label="Search port" solo-inverted>
                    </v-autocomplete>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-badge :content="dischCostsOnly ? 'Disch costs only' : ''" :value="dischCostsOnly ? 'Disch costs only' : ''" color="primary" overlap>
                                <v-btn v-show="!dischCostsOnlyPort" icon v-on="on" :color="dischCostsOnly ? 'primary' : ''">
                                    <v-icon @click="dischCostsOnly=!dischCostsOnly">mdi-currency-usd</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <span>Set discharge costs only to next added port. Port will not be part of this voyage.</span>
                    </v-tooltip>
                    <v-chip v-if="dischCostsOnlyPort && dischCostsOnlyPort.name" close color="primary" outlined @click:close="removeDischCostsOnlyPort()"
                        :title="'This port is only for freight and discharge costs. It is not part of this voyage.'">
                        {{ dischCostsOnlyPort.name }}
                    </v-chip>
                    <span class="hide-mobile" v-if="typeof features.favoritePorts !== 'undefined' && features.favoritePorts.length > 0">
                        <v-btn v-for="port in features.favoritePorts" :key="port.portId" @click="newPort=port; addPort(newPort, null)" small
                            :title="'Add favorite port: \'' + port.name + '\''" text>{{ port.name }}</v-btn>
                    </span>
                </v-toolbar>
                <v-divider />
                <v-card v-if="pIndex !== null && portCalls[pIndex]" class="cardbg">
                    <v-card-title class="text-h6 cardbgdark pt-2 pb-2 pl-6">
                        {{ portCalls[pIndex] ? portCalls[pIndex].name : '' }} {{ portCalls[pIndex].ddtLocode }}
                        <v-spacer />
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on" :disabled="pIndex === 0">
                                    <v-icon @click="movePortPosition(pIndex, 'up')">mdi-arrow-up</v-icon>
                                </v-btn>
                            </template>
                            <span>Move port position upwards in schedule</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on" :disabled="pIndex === portCalls.length-1">
                                    <v-icon @click="movePortPosition(pIndex, 'down')">mdi-arrow-down</v-icon>
                                </v-btn>
                            </template>
                            <span>Move port position downwards in schedule</span>
                        </v-tooltip>
                        <v-autocomplete v-show="showChangePort" class="mx-4" style="max-width:300px" v-model="changePort" @input="addPort(changePort, pIndex)"
                            :loading="portLoading" :items="ports" :item-text="portItemText" item-key="id" :search-input.sync="portChangeSearch" cache-items
                            return-object text hide-no-data hide-details label="Change port" solo>
                        </v-autocomplete>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon @click="showChangePort=!showChangePort">mdi-swap-vertical</v-icon>
                                </v-btn>
                            </template>
                            <span>Change port</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon @click="deletePort()">delete_forever</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete port</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon @click="calcSpeedEtaEtd(pIndex, 'ballastToEta')">refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Recalculate ETA/ETD values with normal speed for this and following ports</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon @click="pIndex = null">clear</v-icon>
                                </v-btn>
                            </template>
                            <span>Close port modal</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-layout wrap align-top class="">
                            <v-flex xs12 md4 d-flex style="height:125px">
                                <v-layout wrap align-center class="pa-0 ma-0">
                                    <v-flex xs12 d-flex>
                                        <v-select class="ml-1 mt-0" style="max-width:235px" prepend-icon="category" v-model="portCalls[pIndex].type"
                                            @change="setPortCallTypeNames();setBallastTypePortHoursAndSpeed(pIndex);addMoneyItems(portCalls[pIndex], pIndex, 'changePort')"
                                            :items="portCallTypes" item-key="value" item-text="[name]" item-value="value" label="Port call type" />
                                    </v-flex>
                                    <v-flex xs12 d-flex>
                                        <div>
                                            <v-icon style="display:inline-block" class="ml-1 mr-2">date_range</v-icon>
                                            <div style="display:inline-block;margin-left:2px;margin-top:-40px;width:200px">
                                                <ejs-daterangepicker id="daterangepicker" :focus="onFocusDateRangePicker" format="d/M/y" floatLabelType="Always"
                                                    placeholder="Laycan" v-model="portCalls[pIndex].laycan"></ejs-daterangepicker>
                                            </div>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 md8 d-flex>
                                <v-layout wrap align-center class="pa-0 ma-0">
                                    <v-flex mx-1 xs3 d-flex>
                                        <v-text-field prepend-icon="speed" step="0.1" min="1" type="number" v-model="portCalls[pIndex].speed"
                                            @input="calcSpeedEtaEtd(pIndex, 'speedToEta');etaUpdated=1" label="Speed to port"></v-text-field>
                                    </v-flex>
                                    <v-flex mx-1 xs5 sm4 d-flex>
                                        <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="portCalls[pIndex].etaDate"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template #activator="{ on }">
                                                <v-text-field v-model="portCalls[pIndex].etaDate" label="ETA Date" v-maska:[dateMask]
                                                    @change="updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etaToSpeed');etaUpdated=1">
                                                    <template #prepend>
                                                        <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="portCalls[pIndex].etaDate"
                                                @change="$refs.sdmenu.save(portCalls[pIndex].etaDate);updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etaToSpeed');etaUpdated=1"
                                                no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.sdmenu.save(portCalls[pIndex].etaDate)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                    <v-flex mx-1 xs3 d-flex>
                                        <v-menu ref="stmenu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                            :return-value.sync="portCalls[pIndex].etaTime" transition="scale-transition" offset-y max-width="290px"
                                            min-width="290px">
                                            <template #activator="{ on }">
                                                <v-text-field v-model="portCalls[pIndex].etaTime" label="ETA time" v-maska:[timeMask]
                                                    @change="updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etaToSpeed');etaUpdated=1" prepend-icon="access_time">
                                                    <template #prepend>
                                                        <v-icon v-on="on" color="primary">mdi-clock-outline</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-time-picker v-if="startTimeMenu" scrollable v-model="portCalls[pIndex].etaTime" format="24hr"
                                                @change="updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etaToSpeed');etaUpdated=1" 
                                                @click:minute="$refs.stmenu.save(portCalls[pIndex].etaTime)"></v-time-picker>
                                        </v-menu>
                                    </v-flex>
                                    <v-flex mx-1 xs3 d-flex>
                                        <!-- <v-text-field prepend-icon="timelapse" title="Must be in format hrs:mins, e.g. 024:00"
                                            return-masked-value mask="###:##" @input="handlePortHoursInput(pIndex)"
                                            v-model="portCalls[pIndex].portHours" label="Hours in port"></v-text-field> -->
                                        <v-text-field prepend-icon="timelapse" step="0.5" min="1" type="number" title="Use decimal hours, e.g. 24,5"
                                            v-model="portCalls[pIndex].portHours" @input="handlePortHoursInput(pIndex)" label="Port hours"></v-text-field>
                                    </v-flex>
                                    <v-flex mx-1 xs5 sm4 d-flex>
                                        <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="portCalls[pIndex].etdDate"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template #activator="{ on }">
                                                <v-text-field v-model="portCalls[pIndex].etdDate" label="ETD Date" v-maska:[dateMask]
                                                    @change="updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etdToEtaEtd');calcPortHours(pIndex)"
                                                    prepend-icon="event">
                                                    <template #prepend>
                                                        <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="portCalls[pIndex].etdDate"
                                                @change="$refs.edmenu.save(portCalls[pIndex].etdDate);updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etdToEtaEtd');calcPortHours(pIndex)"
                                                no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                                                <v-btn text color="primary" @click="$refs.edmenu.save(portCalls[pIndex].etdDate)">OK</v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                    <v-flex mx-1 xs3 d-flex>
                                        <v-menu ref="etmenu" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                            :return-value.sync="portCalls[pIndex].etdTime" transition="scale-transition" offset-y max-width="290px"
                                            min-width="290px">
                                            <template #activator="{ on }">
                                                <v-text-field v-model="portCalls[pIndex].etdTime" label="ETD time" v-maska:[timeMask]
                                                    @change="updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etdToEtaEtd');calcPortHours(pIndex)"
                                                    prepend-icon="access_time">
                                                    <template #prepend>
                                                        <v-icon v-on="on" color="primary">mdi-clock-outline</v-icon>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-time-picker v-if="endTimeMenu" scrollable v-model="portCalls[pIndex].etdTime" format="24hr"
                                                @change="updateDateTime(pIndex);calcSpeedEtaEtd(pIndex, 'etdToEtaEtd');calcPortHours(pIndex)" 
                                                @click:minute="$refs.etmenu.save(portCalls[pIndex].etdTime)"></v-time-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
                <v-divider v-show="pIndex !== null && portCalls[pIndex]" />
            </div>
            <div v-if="isVoyage !== 1">
                <!-- Project type non-voyage -->
                <v-card>
                    <v-card-text>
                        <v-layout wrap align-center>
                            <v-flex xs6 sm5 md3 d-flex>
                                <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="startDate"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="startDate" label="Start date" prepend-icon="event" v-maska:[dateMask]>
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker @change="$refs.sdmenu.save(startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="startDate"
                                        no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.sdmenu.save(startDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs6 sm4 md2 d-flex v-if="isVoyage !== 3">
                                <v-menu ref="stmenu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="startTime"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="startTime" label="Start time" prepend-icon="access_time" v-maska:[timeMask]>
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-clock-outline</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="startTimeMenu" scrollable v-model="startTime" format="24hr" 
                                        @click:minute="$refs.stmenu.save(startTime)">
                                    </v-time-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs6 sm5 md3 d-flex>
                                <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="endDate" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="endDate" label="End date" prepend-icon="event" v-maska:[dateMask]>
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker @change="$refs.edmenu.save(endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="endDate"
                                        no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.edmenu.save(endDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs6 sm4 md2 d-flex v-if="isVoyage !== 3">
                                <v-menu ref="etmenu" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="endTime"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="endTime" label="End time" prepend-icon="access_time" v-maska:[timeMask]>
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-clock-outline</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="endTimeMenu" scrollable v-model="endTime" format="24hr" 
                                        @click:minute="$refs.etmenu.save(endTime)">
                                    </v-time-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </div>

            <!-- Cargo project type-->
            <v-toolbar v-if="isVoyage === 3" class="cardbgdark" dense>
                Add Cargo
                <v-autocomplete v-model="newCargo" @input="addCargo()" :loading="cargoLoading" :items="cargos" :item-text="cargoItemText" item-key="id"
                    :search-input.sync="cargoSearch" cache-items return-object class="mx-4" text hide-no-data hide-details label="Search cargo" solo-inverted>
                </v-autocomplete>
            </v-toolbar>
            <SchedulerCargo v-if="isVoyage === 3 && moneyData" :key="cargoTableKey" :resource="resource" :isVoyage="isVoyage"
                :moneyData="moneyData" :projectId="id" :params="params"></SchedulerCargo>

            <SchedulerMoney v-if="portCalls && isVoyage === 1 && moneyData" :key="moneyTableKey" :portCallData="portCalls" :resource="resource"
                :projectStatus="status" :projectTypeId="projectTypeId" :isVoyage="isVoyage" :prevPortData="prevPortData" :voyageTotals="voyageTotals"
                :moneyData="moneyData" :projectId="id" :params="params" :shipIdNominated="shipIdNominated"></SchedulerMoney>
            <v-card id="voyageSettings">
                <v-card-text class="pb-0 mb-0" v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                    <v-layout wrap align-top>
                        <v-flex xs12 md6 d-flex>
                            <v-layout wrap align-top>
                                <v-flex mt-2 xs12 md6 d-flex v-if="isVoyage === 1">
                                    <v-autocomplete style="height:62px !important" class="mr-4" v-show="!contactName" prepend-icon="face" v-model="contact"
                                        :loading="contactLoading" :items="contacts" :item-text="contactItemText" item-key="id" :search-input.sync="contactSearch" clearable
                                        @change="updateContact" cache-items return-object text hide-no-data hide-details label="Customer"></v-autocomplete>
                                    <v-text-field v-show="contactName" prepend-icon="face" class="mr-4" :readonly="true" v-model="contactName" label="Customer" clearable
                                        @click:clear="onClearClicked()"></v-text-field>
                                </v-flex>
                                <v-flex mt-2 xs12 md6 d-flex v-if="isVoyage === 1">
                                    <v-menu ref="bdmenu" v-model="blDateMenu" :close-on-content-click="true" :return-value.sync="blDate" transition="scale-transition"
                                        offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="blDate" class="mr-4" label="B/L date" v-maska:[dateMask]>
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                                <template #append-outer>
                                                    <v-switch style="" class="my-n1" v-model="autoCode" :false-value="0" :true-value="1"
                                                        :label="autoCode === 1 ? 'On' : 'Off'" hint="Auto update" persistent-hint />
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="blDate" @change="$refs.bdmenu.save(blDate)" no-title
                                            scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="blDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.bdmenu.save(blDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs12 d-flex>
                                    <v-textarea class="mr-3 mt-3" prepend-icon="notes" :rows="12" v-model="notes" name="notes" label="Notes" append-outer-icon="clear"
                                        @click:append-outer="notes=''" value="" hint=""></v-textarea>
                                </v-flex>
                                <!-- <v-flex xs12 sm6 d-flex v-if="isVoyage === 1">
                                    <v-textarea class="mr-3 mt-3" prepend-icon="notes" :rows="5" v-model="recap" name="recap" label="Recap" value=""
                                        append-outer-icon="clear" @click:append-outer="recap=''" hint=""></v-textarea>
                                </v-flex> -->
                            </v-layout>
                        </v-flex>

                        <!-- <v-flex xs0 sm1 d-flex v-if="isVoyage === 1"></v-flex> -->
                        <v-flex mt-2 xs12 md6 d-flex v-if="isVoyage === 1">
                            <v-layout wrap align-top>
                            <v-flex xs12>
                                <v-file-input v-model="files" style="max-width: 320px;" color="primary" counter label="Upload files" multiple class="mr-4"
                                    accept="application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/zip, text/plain, text/csv, application/rtf, image/png, image/jpeg, image/bmp, application/vnd.ms-outlook"
                                    prepend-icon="mdi-paperclip" :show-size="1000" @change="showFileDialog()">
                                    <template #selection="{ index, text }">
                                        <v-chip v-if="index < 2" color="primary" dark label small>
                                            {{ text }}
                                        </v-chip>
                                        <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                                            +{{ files.length - 2 }} File(s)
                                        </span>
                                    </template>
                                    <!-- <template #append-outer>
                                        <v-icon title="Save files" :color="files && files.length > 0 ? 'primary' : 'grey'" @click="callUploadFiles">
                                            mdi-content-save-outline
                                        </v-icon>
                                    </template> -->
                                </v-file-input>
                            </v-flex>
                            <v-flex xs12>
                                <v-simple-table dense height="225">
                                    <template #default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">File</th>
                                            <th class="text-left">Size</th>
                                            <th class="text-left">Author</th>
                                            <th class="text-left">Time</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="file in projectFiles" :key="file.id" style="cursor: pointer;">
                                            <td @click="callDownloadFile(file)">{{ file.name }}</td>
                                            <td @click="callDownloadFile(file)">{{ file.size }}</td>
                                            <td @click="callDownloadFile(file)">{{ file.author }}</td>
                                            <td @click="callDownloadFile(file)">{{ formatTime(file.time) }}</td>
                                            <td><v-icon color="red" title="Delete file permanently" @click="callDeleteFile(file)">mdi-close-circle</v-icon></td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-flex>
                        </v-layout>
                        </v-flex>
                        <!-- <v-flex mt-4 xs12 sm6 md8 lg4 v-if="isVoyage === 1" style="overflow:auto;max-height:110px" class="file-list">
                            <div v-show="!projectFiles || projectFiles.length === 0">No uploaded files</div>
                            <v-chip v-for="file in projectFiles" class="mr-1 mb-1" small :key="file.id" close color="primary" outlined
                                @click="callDownloadFile(file)" @click:close="callDeleteFile(file)"
                                :title="file.name + ' (' + file.size + ')' + ' - Uploaded by ' + file.author + ' (' + formatTime(file.time) + ')'">
                                {{ formatName(file.name) }}
                            </v-chip>
                        </v-flex> -->
</v-layout>
                </v-card-text>
            </v-card>
        </main>
        <v-card class="cardbgdark">
            <v-card-actions>
                <!-- <v-btn color="error" @click="callDeleteProject()">Delete <v-icon right class="hidden-xs-only">
                        delete_forever
                    </v-icon>
                </v-btn> -->
                <v-btn color="error" @click="cancelProjectEdit()">
                    Cancel <v-icon right class="hidden-xs-only">
                        cancel
                    </v-icon>
                </v-btn>
                <v-spacer />
                <div v-if="lastEditorId !== null && lastEditorId !== getUser.user.id" class="mx-4">
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-btn icon v-on="on" @click="callUnlockProject()">
                                <v-icon color="error">mdi-lock-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ showLockText() }}</span>
                    </v-tooltip>
                </div>
                <v-btn color="primary" @click="saveHandler()" :disabled="saveDisabled">
                    Save <v-icon right class="hidden-xs-only">check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <RouteViewer v-model="showRouteViewer" v-if="showRouteViewer && portCalls && portCalls.length > 1" :portCalls="portCalls" :prevPort="prevPort"
            @close="onRouteViewerClose"></RouteViewer>
        <ProgressCircular />
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    File upload
                </v-card-title>

                <v-card-text>
                    <v-list>
                        <span v-for="(filename, index) in filenames" :key="index">
                            <v-list-item>
                                <v-icon color="logo" class="pr-1" style="margin-right:5px"> mdi-file-edit-outline</v-icon>
                                <v-list-item-title>
                                    <v-text-field :rules="[rules.required, rules.counter]" counter maxlength="80" v-model="filenames[index].name"
                                        :label="'File ' + (index + 1)" clearable></v-text-field>
                                </v-list-item-title>
                                <v-chip class="ml-4 pr-5" title="File extension" color="primary">.{{ filename.ext }} </v-chip>
                            </v-list-item>
                        </span>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="dialog=false;files = [];filenames = []">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="callUploadFiles()">
                        Upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import moment from 'moment';
    import { vMaska } from "maska";
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import {
        GridPlugin,
        Selection,
        RowDD,
        CommandColumn,
    } from '@syncfusion/ej2-vue-grids';
    import {
        DateRangePickerPlugin
    } from '@syncfusion/ej2-vue-calendars';
    import SchedulerMoney from './SchedulerMoney.vue';
    import SchedulerCargo from './SchedulerCargo.vue';
    import RouteViewer from './RouteViewer.vue';
    import ProgressCircular from '../ProgressCircular.vue';
    import {
        eventBus
    } from '../../main';
    import {
        tools
    } from '../../helpers';

    Vue.use(DateRangePickerPlugin);
    Vue.use(GridPlugin);

    export default {
        name: 'SchedulerEditor',
        directives: { maska: vMaska },
        props: {
            value: [Boolean, Object],
            eventRecord: Object,
            events: Array,
            eventStore: Object,
            resource: Object,
            params: Object,
        },
        components: {
            SchedulerMoney,
            SchedulerCargo,
            RouteViewer,
            ProgressCircular,
        },
        data() {
            return {
                dialog: false,
                open: false,
                portSearch: null,
                cargoSearch: null,
                portChangeSearch: null,
                contactSearch: null,
                ports: [],
                cargos: [],
                contacts: [],
                portLoading: false,
                cargoLoading: false,
                contactLoading: false,
                id: null,
                resourceName: '',
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                startDateMenu: false,
                startTimeMenu: false,
                endDateMenu: false,
                endTimeMenu: false,
                blDate: null,
                blDateMenu: false,
                autoCode: 1,
                projectTypeId: null,
                isVoyage: null,
                laycan: null,
                modified: null,
                authorName: null,
                contactId: null,
                contactName: null,
                contact: {},
                prevPort: 0,
                prevPortData: {},
                projectType: {},
                newPort: {},
                newCargo: {},
                changePort: {},
                portCalls: [],
                portCallIds: [],
                moneyData: null,
                moneyTableKey: 0,
                cargoTableKey: 0,
                count: 0,
                voyageTotals: {},
                pIndex: null,
                notes: '',
                recap: '',
                code: null,
                shipIdNominated: null,
                status: 0,
                etdKey: 0,
                etaUpdated: 0,
                autoCalcEtdEta: true,
                showRouteViewer: false,
                routes: null,
                ballast: null,
                lastEdit: null,
                lastEditName: null,
                lastEditorId: null,
                trafficArea: null,
                gridSettings: {
                    allowSelection: true,
                },
                disableSave: false,
                portCallTypes: [{
                        value: 2,
                        name: 'Loading'
                    },
                    {
                        value: 3,
                        name: 'Discharging'
                    },
                    {
                        value: 7,
                        name: 'Load/Disch'
                    },
                    {
                        value: 8,
                        name: 'Ballast'
                    },
                    {
                        value: 6,
                        name: 'Transit'
                    },
                    {
                        value: 1,
                        name: 'Waiting/Anchoring'
                    },
                    {
                        value: 4,
                        name: 'Dock/Repairs'
                    },
                    {
                        value: 5,
                        name: 'Off hire'
                    },
                ],
                statusTypes: [{
                        value: 0,
                        name: 'Planned'
                    },
                    {
                        value: 1,
                        name: 'Nominated'
                    },
                    {
                        value: 2,
                        name: 'Confirmed'
                    },
                    {
                        value: 3,
                        name: 'Cloned'
                    },
                    // {
                    //     value: 4,
                    //     name: 'Draft'
                    // },
                ],
                files: [],
                projectFiles: [],
                filenames: [],
                updateFreight: true,
                trafficAreas: [{
                        value: '21',
                        text: '21 France & UK'
                    },
                    {
                        value: '35',
                        text: '35 PRS pulp CE & WE'
                    },
                    {
                        value: '36',
                        text: '36 Mussalo pulp CE & WE'
                    },
                    {
                        value: '43',
                        text: '43 Rauma, Rostock'
                    },
                    {
                        value: '54',
                        text: '54 KTK, RAU, FRO, SDR, AMS'
                    },
                    {
                        value: '64',
                        text: '64 USA'
                    },
                    {
                        value: '91',
                        text: '91 Raw materials'
                    },
                    {
                        value: '88',
                        text: '88 Overseas china clay'
                    },
                    {
                        value: '89',
                        text: '89 Europe china clay'
                    },
                    {
                        value: '95',
                        text: '95 Other imports'
                    },
                    {
                        value: '38',
                        text: '38 External'
                    },
                ],
                showChangePort: false,
                fairwayCount: null,
                dischCostsOnly: false,
                dischCostsOnlyPort: null,
                rules: {
                    required: value => !!value || 'Required.',
                    counter: value => value.length <= 80 || 'Max 80 characters',
                    code: value => this.checkCode(value) || 'Invalid project code - please check',
                },
                deleteFileId: null,
                commands: [{
                    buttonOption: {
                        content: '@', //❏
                        cssClass: 'custombutton',
                        title: 'Send ETA update email.'
                    },
                }],
                dateMask: { mask: 'QW##-%#-X#', eager: true, tokens: {'Q': {pattern: /[2]/}, 'W': {pattern: /[0]/}, '%': {pattern: /[0-1]/}, 'X': {pattern: /[0-3]/}}},
                timeMask: { mask: '%#:X#', eager: true, tokens: {'%': {pattern: /[0-2]/}, 'X': {pattern: /[0-5]/}}},
            };
        },
        created() {
            eventBus.$on('moneyDataUpdate', (e) => {
                // console.log('moneyDataUpdate', e);
                this.moneyData = e;
                this.recalculateMoneyData();
            });
            eventBus.$on('deleteProject', () => {
                this.deleteProject({
                    ids: [this.id]
                });
                this.clearValues();
                this.$emit('input', false);
                this.$emit('close');
            });
            eventBus.$on('disableSave', (value) => {
                this.disableSave = value;
            });
            eventBus.$on('deleteFile', () => {
                if (this.deleteFileId) {
                    this.deleteFile(this.deleteFileId).then((data) => {
                        this.projectFiles = this.convertFileSize(data);
                        this.alertSuccess('File deleted successfully');
                    });
                    this.deleteFileId = null;
                }
            });
            eventBus.$on('finalizeSave', () => {
                this.finalizeSave();
                this.alertClear();
            });
            if (this.eventRecord) {
                if (this.eventRecord.isVoyage === 2) {
                    // this.$emit('input', false);
                } else {
                    this.assignEventRecordValues(this.eventRecord);
                    // Default items if new project
                    if (!this.eventRecord.modified) {
                        setTimeout(() => {
                            this.setProjectType();
                            this.recalculateMoneyData();
                        }, 1000);
                    } else {
                        // Get lock latest status if old case is edited
                        this.fetchLockStatus({
                            id: this.id
                        }).then((data) => {
                            // console.log('Lock status checked from server: ', data);
                            if (data) {
                                this.lastEdit = moment.utc(data.lastEdit);
                                this.lastEditorId = data.lastEditorId;
                                this.lastEditName = data.lastEditName;
                            }
                        });
                    }
                }
            }
            this.listFiles(this.id).then((data) => {
                this.projectFiles = this.convertFileSize(data);
            });
            setTimeout(() => {
                eventBus.$emit('updatePortCalls', this.portCalls);
            }, 100);

            // Set auto update to false if project startDate is in the past for new project
            if (moment(this.startDate).valueOf() < moment().valueOf() && this.id !== -1) {
                this.updateFreight = false;
                this.autoCalcEtdEta = false;
            }
        },
        beforeDestroy() {
            eventBus.$off('incomeInitial');
            eventBus.$off('deleteProject');
            eventBus.$off('deleteFile');
            eventBus.$off('disableSave');
            eventBus.$off('moneyDataUpdate');
            eventBus.$off('finalizeSave');
        },
        watch: {
            portSearch(val) {
                if (val && val !== this.select) {
                    this.portQuerySelections(val);
                }
            },
            cargoSearch(val) {
                if (val && val !== this.select) {
                    this.cargoQuerySelections(val);
                }
            },
            portChangeSearch(val) {
                if (val && val !== this.select) {
                    this.portQuerySelections(val);
                }
            },
            contactSearch(val) {
                if (val && val !== this.select) {
                    this.contactQuerySelections(val);
                }
            }
        },
        methods: {
            ...mapGetters({
                getShips: 'data/getShips',
                getFirstDayOfWeek: 'data/getFirstDayOfWeek',
            }),
            ...mapActions({
                fetchPorts: 'data/fetchPorts',
                fetchCargos: 'data/fetchCargos',
                fetchContacts: 'data/fetchContacts',
                fetchSchedulerRoutes: 'data/fetchSchedulerRoutes',
                fetchPortCalls: 'data/fetchPortCalls',
                fetchMoneyData: 'data/fetchMoneyData',
                fetchFairwayCount: 'data/fetchFairwayCount',
                fetchAvgDa: 'data/fetchAvgDa',
                deleteProject: 'data/deleteProject',
                updateProject: 'data/updateProject',
                uploadFiles: 'data/uploadFiles',
                listFiles: 'data/listFiles',
                deleteFile: 'data/deleteFile',
                downloadFile: 'data/downloadFile',
                getPresignPostS3: 'data/getPresignPostS3',
                uploadFileS3: 'data/uploadFileS3',
                uploadFileSuccessS3: 'data/uploadFileSuccessS3',
                getPresignUrlS3: 'data/getPresignUrlS3',
                downloadFileS3: 'data/downloadFileS3',
                unlockProject: 'data/unlockProject',
                fetchLockStatus: 'data/fetchLockStatus',
                checkProjectCode: 'data/checkProjectCode',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertMissingConfirm: 'alert/missingConfirm',
                alertError: 'alert/error',
                alertWarning: 'alert/warning',
                alertClear: 'alert/clear',
                progressOn: 'data/progressOn',
                progressOff: 'data/progressOff',
                alertSuccess: 'alert/success',
                setDischCostsOnlyPort: 'data/setDischCostsOnlyPort',
                setLastOpenedEvent: 'data/setLastOpenedEvent',
            }),
            portQuerySelections(v) {
                if (v.length > 2) {
                    this.portLoading = true;
                    this.fetchPorts({
                        query: v
                    }).then((data) => {
                        this.ports = data;
                        this.portLoading = false;
                    });
                }
            },
            cargoQuerySelections(v) {
                if (v.length > 2) {
                    this.cargoLoading = true;
                    this.fetchCargos({
                        query: v
                    }).then((data) => {
                        this.cargos = data;
                        this.cargoLoading = false;
                    });
                }
            },
            contactQuerySelections(v) {
                if (v.length > 2) {
                    this.contactLoading = true;
                    this.fetchContacts({
                        query: v,
                        type: 1,
                    }).then((data) => {
                        this.contacts = data;
                        this.contactLoading = false;
                    });
                }
            },
            portItemText(item) {
                return `${item.name} — ${item.ddtLocode}`;
            },
            cargoItemText(item) {
                if (item.pol && item.pod) {
                    return `${item.name} — ${item.pol} — ${item.pod}`;
                }
                return item.name;
            },
            contactItemText(item) {
                // if (item.name && item.locode) {
                //     return item.name + ' — ' + item.locode;
                // } else if (item.name && item.pics) {
                //     return item.name + ' — ' + item.pics;
                // } else {
                return item.name;
                // }
            },
            updateContact(item) {
                if (item) {
                    this.contactId = item.id;
                    this.contactName = item.name;
                } else {
                    this.contactId = null;
                    this.contactName = null;
                }
            },
            onClearClicked() {
                console.log('onClearClicked');
                this.contact = {};
                this.contactId = null;
                this.contactName = null;
            },
            customizeCell(args) {
                if (this.dark) {
                    args.cell.classList.add('light-text');
                }
                // console.log(args.column.field)
                if (args.column.field === 'eta') {
                    if (moment(args.data.eta).format('d') === '6') {
                        args.cell.classList.add('saturday');
                    } else if (moment(args.data.eta).format('d') === '0') {
                        args.cell.classList.add('sunday');
                    }
                }
                if (args.column.field === 'etd') {
                    if (moment(args.data.etd).format('d') === '6') {
                        args.cell.classList.add('saturday');
                    } else if (moment(args.data.etd).format('d') === '0') {
                        args.cell.classList.add('sunday');
                    }
                }
                if (args.data.seaText === 'Route not found!') {
                    args.cell.classList.add('route-error');
                }
                if (args.column.field === 'name') {
                    if (args.data.agentName || args.data.maxDraft || args.data.maxLoa || args.data.maxBeam || args.data.notes) {
                        let maxDraft = args.data.maxDraft ? 'Max draft: ' + args.data.maxDraft + ' m &#x0a' : '';
                        let maxLoa = args.data.maxLoa ? 'Max LOA: ' + args.data.maxLoa + ' m &#x0a' : '';
                        let maxBeam = args.data.maxBeam ? 'Max beam: ' + args.data.maxBeam + ' m &#x0a' : '';
                        let agentName = args.data.agentName ? 'Default agent: ' + args.data.agentName + ' &#x0a' : '';
                        let notes = args.data.notes ? 'Notes: ' + args.data.notes : '';
                        let title = ` ${args.data.name} - ${args.data.ddtLocode}&#x0a ${maxDraft} ${maxLoa} ${maxBeam} ${agentName} ${notes}`;
                        args.cell.innerHTML = args.data.name + ' &nbsp<span title="' + title + '">ⓘ</span>';
                    }
                }
                // if (args.column.field === 'etaUpdate') {
                //     args.cell.innerHTML = 'ee<span class="e-icons e-date"></span>';
                // }
            },
            async saveHandler() {
                // check if project code is missing or already in use or invalid
                let codeStr = '';
                if (this.status !== 3) {
                    if (!this.code) {
                        codeStr = '<p><b>Please set project code.</b></p>';
                    } else {
                        if (!this.checkCode(this.code)) {
                            this.alertError('Please set valid project code before saving project with confirmed status.');
                            return;
                        }
                        let data = await this.checkProjectCode({code: this.code, id: this.id});
                        if (data && data.length > 0) {
                            let codeUsed = '<br/>' + data.map((o => {return o.name + ' project ' + o.id })).join('<br/>');
                            codeStr = `<p><b>Project code "${this.code}" is already used by:</b>${codeUsed}</p>`;
                        }
                    }
                }
                
                let projectItems = '';
                let moneyItems = '';
                let sumFinalZeroItems = '';
                // Check that mandatory values are set
                if (this.isVoyage === 1) {
                    let mandatory = this.features.projectMandatory;
                    if (mandatory && mandatory.length > 0) {
                        let notSet = [];
                        if (mandatory && mandatory.length > 0) {
                            for (const key in mandatory) {
                                if (Object.hasOwnProperty.call(mandatory, key)) {
                                    if (!this[mandatory[key].key] && this[mandatory[key].key] != 0) {
                                        notSet.push(mandatory[key].name);
                                    }
                                }
                            }
                            if (notSet && notSet.length > 0) {
                                projectItems = `<p><b>Please set following project fields:</b><br/>${notSet.join('<br/>')}</p>`;
                            }
                        }
                    }
                }
                if (this.isVoyage === 1 && this.moneyData && this.moneyData.length > 0) {
                    // Check that mandatory moneyItem fields are filled
                    let mandatory = this.features.moneyMandatory;
                    if (mandatory && mandatory.length > 0) {
                        let notSet = this.moneyData.filter((o) => {
                            if (o.sumFinal !== 0) {
                                o.itemsNotSet = [];
                                for (let i = 0, il = mandatory.length; i < il; i++) {
                                    if (o[mandatory[i].field] === mandatory[i].value || mandatory[i].value === 'all') {
                                        for (const item of mandatory[i].items) {
                                            if (!o[item.value]) {
                                                o.itemsNotSet.push(item.name);
                                            }
                                        }
                                    } 
                                }
                                if (o.itemsNotSet && o.itemsNotSet.length > 0) {
                                    o.itemsNotSet = o.itemsNotSet.join(', ');
                                    return o;
                                }
                            }  
                        })
                        if (notSet && notSet.length > 0) {
                            moneyItems = notSet.map((o => {return '<b>' + o.moneyItemName + ':</b> '+ o.itemsNotSet })).join('<br/>');
                            moneyItems = `<p><b>Please set following money fields:</b><br/>${moneyItems}</p>`;
                        }
                    }
                    // Check if items have confirmed status and sumFinal = 0
                    sumFinalZeroItems = this.moneyData.filter((o) => o.sumFinal === 0 && o.status == 1).map(o => o.moneyItemName);
                    if (sumFinalZeroItems && sumFinalZeroItems.length > 0) {
                        sumFinalZeroItems = `<p><b>Following confirmed items have sum final = 0:</b><br/>${sumFinalZeroItems.join(', ')}</p>`;
                    } else {
                        sumFinalZeroItems = '';
                    }
                    console.log(sumFinalZeroItems)
                           
                }
                if (codeStr || projectItems || moneyItems || sumFinalZeroItems) {
                    this.alertMissingConfirm(
                        { message: codeStr + projectItems + moneyItems + sumFinalZeroItems, emit: 'finalizeSave' }
                    );
                    return;
                }
                await this.finalizeSave();
            },
            async finalizeSave() {
                let startDate;
                let endDate;
                let portCalls = [];
                // Project type is voyage, set common project values
                if (this.isVoyage === 1) {
                    if (this.portCalls && this.portCalls.length > 0) {
                        startDate = moment(this.portCalls[0].eta).toDate();
                        endDate = moment(this.portCalls[this.portCalls.length - 1].etd).toDate();
                        portCalls = this.portCalls;
                    }
                } else {
                    this.projectType = this.params.projectTypes.find((o) => o.id === this.projectTypeId);
                    startDate = moment(`${this.startDate}T${this.startTime}`).toDate();
                    endDate = moment(`${this.endDate}T${this.endTime}`).toDate();
                }
                // console.log(startDate, endDate, portCalls); 
                // Set values to record
                const record = {
                    shipId: this.resource.shipId,
                    resourceId: this.resource.id,
                    startDate,
                    endDate,
                    laycan: this.laycan,
                    notes: this.notes,
                    recap: this.recap,
                    code: this.code,
                    shipIdNominated: this.shipIdNominated,
                    blDate: this.blDate,
                    autoCode: this.autoCode,
                    trafficArea: this.trafficArea,
                    status: this.status,
                    contactId: this.contactId,
                    modified: this.modified,
                    prevPort: this.prevPort,
                    projectTypeId: this.projectTypeId,
                    isVoyage: this.isVoyage,
                    portCalls,
                    moneyData: this.moneyData,
                    dischCostsOnlyPort: this.dischCostsOnlyPort,
                    etaUpdated: this.etaUpdated, // This marks that voyage eta/etd is edited not dragged.
                    id: this.id,
                };

                if (this.eventStore && typeof this.eventRecord.set === 'function') { // Edited from bryntum scheduler -> saved by bryntum scheduler logic
                    // console.log(this.eventRecord);
                    this.eventRecord.set(record);
                    if (!this.eventRecord.eventStore) {
                        await this.eventStore.addAsync(record);
                    }
                } else { // Edited from syncfusion data grid -> manually save logic
                    this.updateProject({
                        data: [record]
                    }).then((res) => {
                        // console.log(res);
                        if (res.success) {
                            this.alertSuccess('Project data saved');
                            eventBus.$emit('reloadDataTable');
                        } else {
                            if (res.message === 'Version mismatch, project cannot be updated') {
                                this.alertError('Project version mismatch. The project has been modified and saved by ' + res.author +
                                    ' after you have opened it. Please close the project and open it again to ensure you have the latest data available.'
                                );
                            } else {
                                console.error('Project data save failed', res.message);
                                this.alertError('Project data save failed');
                            }
                        }
                    });
                }

                // close the editor
                this.clearValues();
                this.$emit('input', false);
                
            },
            movePortPosition(pIndex, direction) {
                if (direction === 'up') {
                    this.portCalls = tools.arrayMove(this.portCalls, pIndex, pIndex - 1);
                } else if (direction === 'down') {
                    this.portCalls = tools.arrayMove(this.portCalls, pIndex, pIndex + 1);
                }
                this.dropEnd();
            },
            dropEnd() {
                setTimeout(() => {
                    if (this.portCalls && this.portCalls.length >= 2) {
                        // is port order changed?
                        const newIds = this.portCalls.map((o) => o.portId);
                        let pIndex = null;
                        if (!(this.portCallIds.every((value, index) => value === newIds[index]))) {
                            console.log('order changed!');
                            for (let i = 0, il = newIds.length; i < il; i++) {
                                if (this.portCallIds[i] !== newIds[i]) {
                                    pIndex = i; // Get first changed port index
                                    console.log('First changed port index is ' + pIndex);
                                    break;
                                }
                            }
                            this.portCallIds = newIds;
                            this.fetchSchedulerRoutes({
                                data: this.portCalls,
                                prevPortData: this.prevPortData,
                            }).then((data) => {
                                this.portCalls = data.map((o, i) => {
                                    o.order = i;
                                    return o;
                                });
                                // Set money item port call order
                                this.moneyData = this.moneyData.map((o) => {
                                    if (o.portCallId) {
                                        const portCall = this.portCalls.find((p) => o.portCallId === p.portCallId);
                                        if (portCall) {
                                            o.order = portCall.order;
                                        }
                                    }
                                    return o;
                                });
                                if (this.autoCalcEtdEta || pIndex === null) {
                                    this.updateEtdEta(false);    
                                } else { // Do not update port before changed port
                                    this.updateEtdEta(false, pIndex);
                                }
                                this.$refs.grid.refresh();
                                this.calcVoyageTotals();
                                this.addMoneyItems(null, 0, 'changePort');
                                this.recalculateMoneyData();
                                // ++this.moneyTableKey; // reload money table
                            });
                        }
                    }
                }, 100);
            },
            // actionComplete(args) {
            //     // console.log(args.requestType, args); // custom Action
            //     if (args && args.rows && args.rows.length >= 2) {
            //         // is port order changed?
            //         const newIds = this.portCalls.map((o) => o.portId);
            //         // console.log(this.portCallIds, newIds);
            //         if (!(this.portCallIds.every((value, index) => value === newIds[index]))) {
            //             console.log('order changed!');
            //             this.portCallIds = newIds;
            //             this.fetchSchedulerRoutes({
            //                 data: this.portCalls,
            //                 prevPortData: this.prevPortData,
            //             }).then((data) => {
            //                 this.portCalls = data.map((o, i) => {
            //                     o.order = i;
            //                     return o;
            //                 });
            //                 // Set money item port call order
            //                 this.moneyData = this.moneyData.map((o) => {
            //                     if (o.portCallId) {
            //                         const portCall = this.portCalls.find((p) => o.portCallId === p.portCallId);
            //                         if (portCall) {
            //                             o.order = portCall.order;
            //                         }
            //                     }
            //                     return o;
            //                 });
            //                 this.updateEtdEta(false);
            //                 this.$refs.grid.refresh();
            //                 this.calcVoyageTotals();
            //                 this.addMoneyItems(null, 0, 'changePort');
            //                 this.recalculateMoneyData();
            //                 // ++this.moneyTableKey; // reload money table
            //             });
            //         }
            //     }
            // },
            assignEventRecordValues(eventRecord) {
                let projectTypeId = null;
                let isVoyage = null;
                if (!eventRecord.data || (eventRecord.data && !eventRecord.data.projectTypeId)) {
                    // Preselect project type based on previous voyage on this resource
                    const voyages = this.events.filter((o) => o.resourceId === this.resource.id && o.isVoyage === 1);
                    voyages.sort((a, b) => (a.data.endDate < b.data.endDate ? 1 : -1));
                    if (voyages && voyages.length > 0) {
                        projectTypeId = voyages[0].data.projectTypeId;
                        this.projectType = this.params.projectTypes.find((o) => o.id === projectTypeId);
                    } else { // not found, select first on list
                        this.projectType = this.params.projectTypes.find((o) => o.isVoyage === 1);
                    }
                    projectTypeId = this.projectType && this.projectType.id ? this.projectType.id : null;
                    isVoyage = projectTypeId ? 1 : 0;
                } else {
                    projectTypeId = eventRecord.data && eventRecord.data.projectTypeId ? eventRecord.data.projectTypeId : null;
                    isVoyage = eventRecord.data && eventRecord.data.isVoyage ? eventRecord.data.isVoyage : null;
                }
                Object.assign(this, {
                    resourceName: this.resource ? this.resource.name : '',
                    resourceId: this.resource.id,
                    projectTypeId,
                    isVoyage,
                    id: eventRecord.data && Number.isInteger(eventRecord.data.id) ? eventRecord.data.id : -1,
                    laycan: eventRecord.data && eventRecord.data.laycan ? eventRecord.data.laycan : null,
                    prevPort: eventRecord.data && eventRecord.data.prevPort ? eventRecord.data.prevPort : 0,
                    notes: eventRecord.data && eventRecord.data.notes ? eventRecord.data.notes : null,
                    recap: eventRecord.data && eventRecord.data.recap ? eventRecord.data.recap : null,
                    code: eventRecord.data && eventRecord.data.code ? eventRecord.data.code : null,
                    shipIdNominated: eventRecord.data && eventRecord.data.shipIdNominated ? eventRecord.data.shipIdNominated : null,
                    autoCode: eventRecord.data ? eventRecord.data.autoCode : 1,
                    trafficArea: eventRecord.data ? eventRecord.data.trafficArea : null,
                    status: eventRecord.data && eventRecord.data.status ? eventRecord.data.status : 0,
                    contactId: eventRecord.data && eventRecord.data.contactId ? eventRecord.data.contactId : null,
                    contactName: eventRecord.data && eventRecord.data.contactName ? eventRecord.data.contactName : null,
                    modified: eventRecord.data && eventRecord.data.modified ? eventRecord.data.modified : null,
                    authorName: eventRecord.data && eventRecord.data.authorName ? eventRecord.data.authorName : null,
                    dischCostsOnlyPort: eventRecord.data && eventRecord.data.dischCostsOnlyPort ? eventRecord.data.dischCostsOnlyPort : null,
                    startDate: moment(eventRecord.startDate).format('YYYY-MM-DD'),
                    startTime: moment(eventRecord.startDate).format('HH:mm'),
                    endDate: moment(eventRecord.endDate).format('YYYY-MM-DD'),
                    endTime: moment(eventRecord.endDate).format('HH:mm'),
                    blDate: eventRecord.data && eventRecord.data.blDate ? eventRecord.data.blDate : null,
                    lastEdit: eventRecord.data && eventRecord.data.lastEdit ? moment.utc(eventRecord.data.lastEdit) : null,
                    lastEditName: eventRecord.data && eventRecord.data.lastEditName ? eventRecord.data.lastEditName : null,
                    lastEditorId: eventRecord.data && eventRecord.data.lastEditorId ? eventRecord.data.lastEditorId : null,
                });
                // Set or empty discharge costs only port
                this.setDischCostsOnlyPort(this.dischCostsOnlyPort);

                this.prevPortData = null;
                // Get portcalls if voyage is edited
                if (Number.isInteger(this.id) && this.id !== -1 && this.isVoyage === 1) {
                    this.setLastOpenedEvent(this.id);
                    this.fetchPortCalls(this.id).then((data) => {
                        this.portCalls = [];
                        setTimeout(() => {
                            this.portCalls = data.portCalls.map((o) => {
                                o.speed = parseFloat(o.speed);
                                o.laycanShort = o.laycan && Array.isArray(o.laycan) ? `${moment(o.laycan[0]).format('DD/MM')} - ${ 
                                    moment(o.laycan[1]).format('DD/MM')}` : null;
                                return o;
                            });
                            this.prevPortData = data.prevPortData;
                            this.portCalls.map((o, i) => {
                                this.calcPortHours(i);
                            });
                            this.setPortCallTypeNames();
                            this.initDatesAndTimes(null);
                            this.portCallIds = this.portCalls.map((o) => o.portId);
                            this.$refs.grid.refresh();
                            this.calcVoyageTotals();
                            // Update BL date
                            if (this.autoCode === 1) {
                                const firstLoading = this.portCalls.find((o) => o.type === 2 || o.type === 7);
                                if (firstLoading && firstLoading.etdDate) {
                                    this.blDate = firstLoading.etdDate;
                                }
                            }
                        }, 10);
                    });
                }
                // Get money data if voyage is edited
                if (Number.isInteger(this.id) && this.id !== -1) {                    
                    this.fetchMoneyData(this.id).then((data) => {
                        this.moneyData = data.money;
                        this.fairwayCount = data.fairwayCount;
                        setTimeout(() => {
                            this.recalculateMoneyData();
                        }, 2000);
                        // console.log('fetchMoneyData', this.moneyData, this.id);
                    });
                } else if (this.isVoyage === 1 && this.resource.shipId) { // if new voyage get fairway count only (for resources with shipId defined)
                    this.fetchFairwayCount({
                        shipId: this.resource.shipId,
                        startDate: this.startDate
                    }).then((data) => {
                        this.fairwayCount = data.fairwayCount;
                        console.log('Fairway count is: ' +this.fairwayCount);
                        // this.recalculateMoneyData();
                    });
                }
            },
            setProjectType() {
                if (this.params && this.params.projectTypes) {
                    this.projectType = this.params.projectTypes.find((o) => o.id === this.projectTypeId);
                }
                // clear values
                this.laycan = null;
                this.notes = '';
                this.recap = '';
                this.code = null;
                this.shipIdNominated = null;
                this.blDate = null;
                this.autoCode = 1;
                this.trafficArea = null;
                this.dischCostsOnlyPort = null;
                this.isVoyage = this.projectType && this.projectType.isVoyage === 1 ? 1 : this.projectType.isVoyage === 3 ? 3 : 0;
                this.status = this.isVoyage === 1 ? 0 : null;
                this.portCalls = [];
                this.prevPortData = null;
                this.contact = {};
                this.contactId = null;
                this.contactName = null;
                // Get ship for moneyitem shipContactRule check
                let ship = {};
                if (this.resource.shipId) {
                    ship = this.ships.find((o) => o.id === this.resource.shipId);
                } else if (this.shipIdNominated) {
                    ship = this.ships.find((o) => o.id === this.shipIdNominated);
                }
                // set default money items based on project type
                this.moneyData = [];
                // console.log('moneyData', this.moneyData, this.projectType);
                // console.log('projectType', this.projectType);
                if (this.projectType && this.projectType.defaultItems && this.projectType.defaultItems.length > 0 && this.params.moneyItems) {
                    for (const itemId of this.projectType.defaultItems) {
                        const moneyItem = this.params.moneyItems.find((o) => itemId === o.id && o.active === 1);
                        if (moneyItem) {
                            const newItem = JSON.parse(JSON.stringify(moneyItem));
                            newItem.moneyItemName = moneyItem.name;
                            newItem.created = moment.utc().format();
                            newItem.name = '';
                            newItem.moneyItemId = moneyItem.id;
                            newItem.tonFinal = 0;
                            newItem.shipId = this.resource.shipId;
                            newItem.id = Date.now() + this.count;
                            ++this.count;
                            newItem.quantity = 0;
                            if (newItem.calcRule === 'bunker') {
                                newItem.rate = tools.isNumeric(this.resource.bunkerRate) ? this.resource.bunkerRate : 0;
                                newItem.rateFinal = newItem.rate;
                            }
                            if (newItem.calcRule === 'bunkerSteaming') {
                                newItem.rate = tools.isNumeric(this.resource.bunkerSteamingRate) ? this.resource.bunkerSteamingRate : 0;
                                newItem.rateFinal = newItem.rate;
                            }
                            if (newItem.calcRule === 'secondaryBunkerSteaming') {
                                newItem.rate = tools.isNumeric(this.resource.secondaryBunkerSteamingRate) ? this.resource.secondaryBunkerSteamingRate : 0;
                                newItem.rateFinal = newItem.rate;
                            }
                            if (newItem.calcRule === 'bunkerPort') {
                                newItem.rate = tools.isNumeric(this.resource.bunkerPortRate) ? this.resource.bunkerPortRate : 0;
                                newItem.rateFinal = newItem.rate;
                            }
                            if (newItem.calcRule === 'day') {
                                newItem.rate = tools.isNumeric(this.resource.dayRate) ? this.resource.dayRate : 0;
                                newItem.rateFinal = newItem.rate;
                            }
                            // Add contactId from ship if shipContactRule is active
                            if (newItem.shipContactRule === 1 && ship && typeof ship.ownerId !== 'undefined') {
                                console.log(`Money item "${newItem.moneyItemName}" has ship contact rule active. Ship contact is: ${ship.ownerName}`);
                                newItem.contactId = ship.ownerId;
                                newItem.contactText = ship.ownerName;
                            }
                            this.moneyData.push(newItem);
                        }
                    }
                }
                // Add ship specific money items
                if (this.params.moneyItems) {
                    const newItems = this.params.moneyItems.filter((o) => o.calcRule === 'ship' && o.shipId === this.resource.shipId && o.active === 1 &&
                        (o.type !== 'Fairway' || (this.fairwayCount !== null && this.fairwayCount < 10)));
                    for (const moneyItem of newItems) {
                        if (moneyItem.type === 'Fairway') {
                            console.log('Add money item fairway count: ' + this.fairwayCount);
                        }
                        const newItem = JSON.parse(JSON.stringify(moneyItem));
                        newItem.moneyItemName = moneyItem.name;
                        newItem.created = moment.utc().format();
                        // newItem.name = '';
                        newItem.moneyItemId = moneyItem.id;
                        newItem.shipId = this.resource.shipId;
                        newItem.id = Date.now() + this.count;
                        ++this.count;
                        newItem.quantity = 1;
                        newItem.tonFinal = 0;
                        this.moneyData.push(newItem);
                    }
                }
                eventBus.$emit('newMoneyData', this.moneyData);
                // ++this.moneyTableKey;
            },
            setConfirmedStatus() {
                // If status is changed to confirmed copy all values to Final values
                if (this.status === 2 && this.moneyData && this.moneyData.length > 0) {
                    // Check that project code is valid
                    if (!this.checkCode(this.code)) {
                        this.alertError('Confirmed status can\'t be set with invalid project code.');
                        this.status = 0;
                        return;
                    }
                    for (const key in this.moneyData) {
                        if (this.moneyData.hasOwnProperty(key) && this.moneyData[key].status !== 1) {
                            if (this.moneyData[key].calcRule === 'loadingCost' || this.moneyData[key].calcRule === 'dischargeCost') {
                                if (!this.moneyData[key].rateFinal) {
                                    this.moneyData[key].rateFinal = this.moneyData[key].rate;
                                }
                            } else {
                                this.moneyData[key].rateFinal = this.moneyData[key].rate;
                            }
                            if (this.moneyData[key].calcRule === 'bunker' || this.moneyData[key].calcRule === 'bunkerSteaming' || this.moneyData[key]
                                .calcRule === 'bunkerPort') {
                                if (!this.moneyData[key].status) {
                                   this.moneyData[key].quantityFinal = 0;
                                   this.moneyData[key].sumFinal = 0;
                                }
                            } else {
                                if (!this.moneyData[key].status) {
                                    this.moneyData[key].quantityFinal = this.moneyData[key].quantity;
                                    this.moneyData[key].sumFinal = this.moneyData[key].sum;
                                }
                            }
                            // Add tonFinal if unit is ton, mton
                            if (this.moneyData[key].unit === 'ton' && this.moneyData[key].type === 'Freight' && this.moneyData[key].income === 1) {
                                this.moneyData[key].tonFinal = this.moneyData[key].quantityFinal;
                            } else if (this.moneyData[key].unit === 'mton' && this.moneyData[key].type === 'Freight' && this.moneyData[key].income === 1) {
                                this.moneyData[key].tonFinal = this.moneyData[key].quantityFinal * 1000;
                            }
                        }
                    }
                    // console.log('newMoneyData', this.moneyData);
                    eventBus.$emit('newMoneyData', this.moneyData);
                    // ++this.moneyTableKey;
                }
            },
            removeDischCostsOnlyPort() {
                this.moneyData = this.moneyData.filter((o) => !(o.quantity === 0 && o.quantityFinal === 0 && o.sumFinal === 0 &&
                    (o.portIdDisch === this.dischCostsOnlyPort.portId || o.portCallId === this.dischCostsOnlyPort.portCallId)));
                this.dischCostsOnlyPort = null;
                this.setDischCostsOnlyPort(null);
                this.recalculateMoneyData();
            },
            addPort(port, pIndex) {
                if (this.dischCostsOnly) { // Dummy discharge port
                    this.dischCostsOnly = false;
                    this.dischCostsOnlyPort = {};
                    this.dischCostsOnlyPort.ddtLocode = port.ddtLocode;
                    this.dischCostsOnlyPort.name = port.name;
                    this.dischCostsOnlyPort.portId = port.portId;
                    this.dischCostsOnlyPort.portCallId = 2147483647;
                    // this.dischCostsOnlyPort.order = this.portCalls.length;
                    this.dischCostsOnlyPort.type = 3;
                    this.setDischCostsOnlyPort(this.dischCostsOnlyPort);
                    this.addMoneyItems(this.dischCostsOnlyPort, pIndex, 'addPort', null, true);
                    return;
                }
                // console.log(JSON.stringify(port));
                port.status = 0;
                let changePort = false;
                if (pIndex !== null) { // change port, use old port values
                    port.type = this.portCalls[pIndex].type;
                    changePort = true;
                } else if (port.portCallType) { // Port call type based on db default port type
                    port.type = port.portCallType;
                } else {
                    const loadingDefined = this.portCalls.findIndex((o) => o.type === 2);
                    if (loadingDefined === -1) {
                        port.type = 2;
                    } else {
                        port.type = 3;
                    }
                }
                port.portHours = typeof port.defaultHours !== 'undefined' ? port.defaultHours : 24;
                port.speed = 12;
                if (this.portCalls && this.portCalls.length === 0) {
                    port.speed = this.resource.ballastSog ? Math.round(this.resource.ballastSog * 10) / 10 : this.resource.normalSog ? Math.round(this
                        .resource.normalSog * 10) / 10 : 12;
                } else if (this.resource.normalSog) {
                    port.speed = Math.round(this.resource.normalSog * 10) / 10;
                }
                if (pIndex !== null) {
                    port.order = this.portCalls[pIndex].order;
                } else {
                    port.order = this.portCalls ? this.portCalls.length : 0;
                }
                const multiply = port.order + 1;
                port.portCallId = multiply * 1000000000 + port.portId;
                port.resourceId = this.resource.id;
                // console.log(port);
                if (this.portCalls === null) { // first port 
                    this.portCalls = [];
                }
                let oldPort;
                if (pIndex !== null) {
                    oldPort = JSON.parse(JSON.stringify(this.portCalls[pIndex]));
                    this.portCalls[pIndex] = port;
                } else {
                    this.portCalls.push(port);
                }
                this.fetchSchedulerRoutes({
                    data: this.portCalls,
                    prevPortData: this.prevPortData
                }).then((data) => {
                    if (!data || data.length === 0) {
                        return;
                    }
                    this.portCalls = [];
                    setTimeout(() => {
                        this.portCalls = data.map((o) => {
                            o.portCallName = o.name;
                            const multiply = o.order + 1;
                            o.portCallId = multiply * 1000000000 + o.portId;
                            return o;
                        });
                        this.portCallIds = this.portCalls.map((o) => o.portId);
                        if (pIndex === null) {
                            this.updateEtdEta(true);
                        } else {
                            if (this.autoCalcEtdEta) {
                                this.updateEtdEta(false);    
                            } else {
                                this.updateEtdEta(false, pIndex);
                            }
                        }
                        this.setPortCallTypeNames();
                        this.$refs.grid.refresh();
                        this.newPort = {};
                        this.changePort = {};

                        // Add money items based on calcRule
                        if (pIndex === null) { // new port
                            pIndex = this.portCalls.length - 1;
                            this.addMoneyItems(this.portCalls[pIndex], pIndex, 'addPort');
                        } else {
                            this.addMoneyItems(this.portCalls[pIndex], pIndex, 'changePort', oldPort);
                        }
                        this.showChangePort = false;

                        // Update BL date
                        if (this.autoCode === 1) {
                            const firstLoading = this.portCalls.find((o) => o.type === 2 || o.type === 7);
                            if (firstLoading && firstLoading.etdDate) {
                                this.blDate = firstLoading.etdDate;
                            }
                        }

                        // eventBus.$emit('portCallsUpdated' , this.portCalls);
                        if (changePort && !this.updateFreight) { // Port changed show dialog
                            this.alertWarning(
                                'Port was changed and "Auto freight" is not activate. Toggle "Auto freight" ON to update freights.');
                        }
                    }, 10);
                });
            },
            addCargo() {
                console.log('addCargo', this.newCargo);
                if (this.newCargo && this.newCargo.moneyItemId) {
                    this.moneyData.push(this.newCargo);
                    this.newCargo = {};
                    this.cargos = [];
                    console.log(this.moneyData);
                    eventBus.$emit('newMoneyData', this.moneyData);
                    ++this.cargoTableKey; // reload cargo table
                }
            },
            changeNominatedShip() {
                // Update money data contactId from ship if shipContactRule is active
                let ship = this.ships.find((o) => o.id === this.shipIdNominated);
                let updateContact = false;
                this.moneyData.map((o) => {
                    if (o.shipContactRule === 1) {
                        if (ship && typeof ship.ownerId !== 'undefined') {
                            console.log(`Money item "${o.moneyItemName}" has ship contact rule active. Ship contact is: ${ship.ownerName}`);
                            o.contactId = ship.ownerId;
                            o.contactText = ship.ownerName;
                        } else {
                            console.log(`Money item "${o.moneyItemName}" has ship contact rule active. Clear contactId since no nominated ship.`);
                            o.contactId = null;
                            o.contactText = null;
                        }
                        updateContact = true;
                    }
                    return o;
                })
                if (updateContact) {
                    eventBus.$emit('newMoneyData', this.moneyData);
                }

                // Update money data
                for (const key in this.portCalls) {
                    if (this.portCalls.hasOwnProperty(key)) {
                        this.addMoneyItems(this.portCalls[key], key, 'changePort');
                    }
                }
            },
            toggleAutoUpdateFreight() {
                if (this.updateFreight) {
                    console.log('Auto update freight ON. Recalculate freights.');
                    this.addMoneyItems(null, 0, 'changePort');
                }
            },
            addMoneyItems(portCall, index, type, oldPort = null, dischCostOnly = false) {
                // console.log('addMoneyItems', portCall, index, type);
                let newItems = [];
                let moneyDataCopy = JSON.parse(JSON.stringify(this.moneyData));
                const moneyItemsCopy = JSON.parse(JSON.stringify(this.params.moneyItems));

                // Get ship brand for moneyitem brand match check
                let ship = {};
                if (this.resource.shipId) {
                    ship = this.ships.find((o) => o.id === this.resource.shipId);
                } else if (this.shipIdNominated) {
                    ship = this.ships.find((o) => o.id === this.shipIdNominated);
                } else {
                    ship.brand = null;
                }
                // console.log('ship brand: ' + index + ' ' + ship.brand + ' ' + this.shipIdNominated);
                if (portCall && portCall.portId) {
                    // If port already exists remove related moneyitems
                    portCall.portCallId = portCall.portCallId ? portCall.portCallId : portCall.id;
                    if (type !== 'addPort' && portCall.portCallId) {
                        // console.log('filter1', portCall.portCallId, moneyDataCopy.filter((o) => o.portCallId === portCall.portCallId).map((o) => o.portCallId).join(', '));
                        moneyDataCopy = moneyDataCopy.filter((o) => o.portCallId !== portCall.portCallId || o.quantity > 0 || o.quantity < 0 || o
                            .quantityFinal > 0 || o.quantityFinal < 0 || o.sumFinal > 0 || o.sumFinal < 0);
                        // console.log('filter2', portCall.portCallId, moneyDataCopy.filter((o) => o.portCallId === portCall.portCallId).map((o) => o.portCallId).join(', '));
                    }
                    // If port was changed remove old port related moneyitems
                    if (oldPort !== null) {
                        moneyDataCopy = moneyDataCopy.filter((o) => o.portCallId !== oldPort.portCallId);
                    }
                    if (portCall.type !== 8) {
                        // Port specific "DA"
                        if (!dischCostOnly) {
                            newItems = this.params.moneyItems.filter((o) => o.calcRule === 'da' && o.portId === portCall.portId && o.active === 1 && (o
                                .projectTypeId === null || this.projectTypeId === o.projectTypeId));
                            if (newItems.length === 0 && portCall.type !== 6) { // Common "DA", if port specific are not found. Exclude transit points
                                newItems = moneyItemsCopy.filter((o) => o.calcRule === 'da' && !o.portId && o.active === 1 && (o.projectTypeId === null || this
                                    .projectTypeId === o.projectTypeId));
                            }
                            // "Port cost"
                            const items = moneyItemsCopy.filter((o) => o.calcRule === 'portCost' && o.portId === portCall.portId && o.active === 1 && (ship
                                .brand ===
                                o.brand || !o.brand) && (o.projectTypeId === null || this.projectTypeId === o.projectTypeId));
                            newItems = newItems.concat(items);
                            // "Loading cost"
                            if (portCall.type === 2 || portCall.type === 7) {
                                const items = moneyItemsCopy.filter((o) => o.calcRule === 'loadingCost' && o.portId === portCall.portId &&
                                    o.active === 1 && (ship.brand === o.brand || !o.brand) && (o.projectTypeId === null || this.projectTypeId === o
                                        .projectTypeId));
                                newItems = newItems.concat(items);
                            }
                        }
                        // "Discharge cost"
                        if (portCall.type === 3 || portCall.type === 7) {
                            const items = moneyItemsCopy.filter((o) => o.calcRule === 'dischargeCost' && o.portId === portCall.portId &&
                                o.active === 1 && (ship.brand === o.brand || !o.brand) && (o.projectTypeId === null || this.projectTypeId === o
                                    .projectTypeId));
                            newItems = newItems.concat(items);
                        }
                    }
                }

                if (this.updateFreight && (type === 'changePort' || type === 'addPort')) {
                    // Delete all items with calcRule freight, except when quantity is over zero
                    moneyDataCopy = moneyDataCopy.filter((o) => (o.portIdLoad === null && o.portIdDisch === null) || o.quantity > 0 || o.quantity < 0 || o
                        .quantityFinal > 0 || o.quantityFinal < 0 || o.sumFinal > 0 || o.sumFinal < 0);
                    // Add freight to all port calls
                    for (let i = 0, il = this.portCalls.length; i < il - 1; i++) { // Do not add to last port freight
                        if (this.portCalls[i].type === 2 || this.portCalls[i].type === 7) {
                            const items = moneyItemsCopy.filter((o) => o.calcRule === 'freight' && o.portIdLoad === this.portCalls[i].portId &&
                                o.active === 1 && (ship.brand === o.brand || !o.brand));
                            if (Array.isArray(items) && items.length > 0) {
                                // loading ports found -> look for discharge ports
                                for (const item of items) {
                                    // Check that moneyItem is not already in moneyData
                                    if (moneyDataCopy.findIndex((o) => o.moneyItemId === item.id) === -1) {
                                        const dischargePortIndex = this.portCalls.findIndex((o, index) => index > i && o.portId === item.portIdDisch && (o
                                            .type === 3 || o.type === 7 || o.type === 8));

                                        if (dischargePortIndex > i || (dischCostOnly && portCall.portId === item.portIdDisch)) {
                                            // disharge port found too -> add to newItems
                                            item.pol = this.portCalls[i].ddtLocode; // Add POL name
                                            if (dischargePortIndex > i) {
                                                item.pod = this.portCalls[dischargePortIndex].ddtLocode; // Add POD name
                                            } else if (dischCostOnly && portCall.portId === item.portIdDisch) {
                                                item.pod = portCall.ddtLocode;
                                                // console.log('Freight to "Disch costs only port" added', item);
                                            }
                                            // use loading port as portCall
                                            item.portId = this.portCalls[i].portId;
                                            item.order = this.portCalls[i].order;
                                            // item.portCallId = 'portId_' + this.portCalls[i].portId;
                                            const multiply = item.order + 1;
                                            item.portCallId = multiply * 1000000000 + this.portCalls[i].portId;
                                            item.portCallName = this.portCalls[i].name;
                                            newItems.push(item);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (newItems.length > 0) {
                    // Filter out items that are already in moneyData with qty/sum != 0
                    if (moneyDataCopy.length > 0) {
                        newItems = newItems.filter((item) => moneyDataCopy.findIndex((money) => item.id === money.moneyItemId && portCall.portCallId === money
                            .portCallId) === -1);
                    }
                    for (const item of newItems) {
                        const newItem = JSON.parse(JSON.stringify(item));
                        newItem.moneyItemName = newItem.name;
                        newItem.moneyItemId = newItem.id;
                        newItem.tonFinal = 0;
                        if (newItem.calcRule === 'portCost' || newItem.calcRule === 'loadingCost' || newItem.calcRule === 'dischargeCost' || newItem
                            .calcRule === 'freight') {
                            newItem.quantity = 0;
                            newItem.quantityFinal = 0;
                        } else {
                            newItem.quantity = 1;
                            newItem.quantityFinal = 1;
                        }
                        newItem.created = moment.utc().format();
                        newItem.id = Date.now() + this.count;
                        ++this.count;
                        if (portCall && portCall.portId) {
                            if (!newItem.portCallId) { // In case Freight we use loading port here, otherwise get port call details
                                newItem.shipId = this.resource.shipId;
                                newItem.portId = portCall.portId;
                                newItem.order = portCall.order;
                                const multiply = newItem.order + 1;
                                newItem.portCallType = portCall.type;
                                if (newItem.calcRule === 'dischargeCost' && dischCostOnly) {
                                    newItem.portCallId = portCall.portCallId; // Static portCallId 
                                } else if (type === 'changePort') {
                                    newItem.portCallId = portCall.portCallId;
                                } else {
                                    newItem.portCallId = multiply * 1000000000 + portCall.portId;
                                }
                                newItem.portCallName = portCall.name;
                            }
                            const sign = newItem.income === 1 ? 1 : -1;
                            // check exchange rate if currency is different than customer base currency
                            newItem.exchangeRate = this.getExchangeRate(newItem.currency);

                            if (this.resource.shipId && newItem.calcRule === 'da') { // Add avgDa if ship is defined and calcRule is "DA"
                                this.fetchAvgDa({
                                    portId: portCall.portId,
                                    shipId: this.resource.shipId
                                }).then((data) => {
                                    newItem.rate = data && data.avgDa !== null ? data.avgDa : 1000;
                                    newItem.sum = Math.round(newItem.rate * newItem.quantity / newItem.exchangeRate * sign * 100) / 100;
                                    newItem.rateFinal = newItem.rate;
                                    newItem.sumFinal = newItem.sum;
                                });
                            } else {
                                newItem.sum = Math.round(newItem.rate * newItem.quantity / newItem.exchangeRate * sign * 100) / 100;
                                newItem.rateFinal = newItem.rate;
                                newItem.sumFinal = newItem.sum;
                            }
                        }
                        // Add contactId from ship if shipContactRule is active
                        if (newItem.shipContactRule === 1 && ship && typeof ship.ownerId !== 'undefined') {
                            console.log(`Money item "${newItem.moneyItemName}" has ship contact rule active. Ship contact is: ${ship.ownerName}`);
                            newItem.contactId = ship.ownerId;
                            newItem.contactText = ship.ownerName;
                        }
                        moneyDataCopy.push(newItem);
                    }
                }
                this.calcVoyageTotals();
                setTimeout(() => {
                    // eventBus.$emit('newMoneyData', this.moneyData);
                    this.moneyData = moneyDataCopy;
                    this.recalculateMoneyData();
                }, 1500);
            },
            deletePort() {
                const portCall = this.portCalls[this.pIndex];
                // remove port related moneyitems 
                const portCallId = portCall.id ? portCall.id : portCall.portCallId ? portCall.portCallId : null;
                if (portCall && portCall.portId && portCallId) {
                    this.moneyData = this.moneyData.filter((o) => !(o.portCallId === portCallId || (o.portIdLoad === portCall.portId && o.quantity === 0 && o
                        .quantityFinal === 0 && o.sumFinal === 0) || (o.portIdDisch === portCall.portId && o.quantity === 0 && o.quantityFinal ===
                        0 && o.sumFinal === 0)));
                }
                this.portCalls.splice(this.pIndex, 1);
                this.pIndex = null;
                this.$refs.grid.refresh();
                this.calcVoyageTotals();
                this.recalculateMoneyData();
            },
            recalculateMoneyData() {
                console.log('recalculateMoneyData', this.moneyData);
                if (this.moneyData && this.moneyData.length > 0) {
                    let fairwayCount = this.fairwayCount;
                    for (const key in this.moneyData) {
                        if (this.moneyData.hasOwnProperty(key)) {
                            const moneyItem = this.params.moneyItems.find((o) => typeof this.moneyData[key].moneyItemId !== 'undefined' && o.id === this
                                .moneyData[key].moneyItemId && o.active === 1);
                            const calcRule = moneyItem && moneyItem.calcRule ? moneyItem.calcRule : null;
                            const sign = this.moneyData[key].income == 1 ? 1 : -1;
                            if (typeof this.moneyData[key].exchangeRate === 'undefined') {
                                this.moneyData[key].exchangeRate = this.getExchangeRate(this.moneyData[key].currency);
                            }
                            if (calcRule === 'bunker') {
                                this.moneyData[key].quantity = this.voyageTotals.cons;
                                if (!this.moneyData[key].status) {
                                    // this.moneyData[key].quantityFinal = this.voyageTotals.cons;
                                }
                                if (!this.moneyData[key].rate) {
                                    // this.moneyData[key].rate = this.resource.bunkerRate;
                                }
                                if (tools.isNumeric(this.moneyData[key].rate)) {
                                    this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.voyageTotals.cons / this.moneyData[key].exchangeRate *
                                        sign * 100) / 100;
                                    // this.moneyData[key].sumFinal = this.moneyData[key].sum;
                                } else {
                                    this.moneyData[key].rate = 0;
                                    this.moneyData[key].sum = 0;
                                    // this.moneyData[key].sumFinal = 0;
                                }
                            } else if (calcRule === 'bunkerSteaming') {
                                this.moneyData[key].quantity = this.voyageTotals.consSteaming;
                                if (!this.moneyData[key].status) {
                                    // this.moneyData[key].quantityFinal = this.voyageTotals.consSteaming;
                                }
                                if (!this.moneyData[key].rate) {
                                    // this.moneyData[key].rate = this.resource.bunkerSteamingRate;
                                }
                                if (tools.isNumeric(this.moneyData[key].rate)) {
                                    this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.voyageTotals.consSteaming / this.moneyData[key]
                                        .exchangeRate * sign * 100) / 100;
                                    // this.moneyData[key].sumFinal = this.moneyData[key].sum;
                                } else {
                                    this.moneyData[key].rate = 0;
                                    this.moneyData[key].sum = 0;
                                    // this.moneyData[key].sumFinal = 0;
                                }
                            } else if (calcRule === 'secondaryBunkerSteaming') {
                                this.moneyData[key].quantity = this.voyageTotals.secondaryConsSteaming;
                                if (tools.isNumeric(this.moneyData[key].rate)) {
                                    this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.voyageTotals.secondaryConsSteaming / this.moneyData[key]
                                        .exchangeRate * sign * 100) / 100;
                                } else {
                                    this.moneyData[key].rate = 0;
                                    this.moneyData[key].sum = 0;
                                }
                            } else if (calcRule === 'bunkerPort') {
                                this.moneyData[key].quantity = this.voyageTotals.consPort;
                                if (!this.moneyData[key].status) {
                                    // this.moneyData[key].quantityFinal = this.voyageTotals.consPort;
                                }
                                if (!this.moneyData[key].rate) {
                                    // this.moneyData[key].rate = this.resource.bunkerPortRate;
                                }
                                if (tools.isNumeric(this.moneyData[key].rate)) {
                                    this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.voyageTotals.consPort / this.moneyData[key]
                                        .exchangeRate * sign * 100) / 100;
                                    // this.moneyData[key].sumFinal = this.moneyData[key].sum;
                                } else {
                                    this.moneyData[key].rate = 0;
                                    this.moneyData[key].sum = 0;
                                    // this.moneyData[key].sumFinal = 0;
                                }
                            } else if (calcRule === 'commission') {
                                // this.moneyData[key].quantity = 0;
                                // if (!this.moneyData[key].status) {
                                //     this.moneyData[key].quantityFinal = 0;
                                // }
                                // this.moneyData.map((o) => {
                                //     if (o.income > 0) {
                                //         this.moneyData[key].quantity += o.sum;
                                //         if (!this.moneyData[key].status) {
                                //             this.moneyData[key].quantityFinal += o.sumFinal;
                                //         }
                                //     }
                                // })
                                // if (tools.isNumeric(this.moneyData[key].rate) && tools.isNumeric(this.moneyData[key].quantity) && this.moneyData[key].quantity > 0) {
                                //     this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.moneyData[key].quantity / this.moneyData[key].exchangeRate * sign / 100 * 100)/100;
                                // }
                                // this.moneyData[key].rateFinal = this.moneyData[key].status ? this.moneyData[key].rateFinal : this.moneyData[key].rate;
                                // if (tools.isNumeric(this.moneyData[key].rateFinal) && tools.isNumeric(this.moneyData[key].quantityFinal) && this.moneyData[key].quantityFinal > 0 && !this.moneyData[key].status) {
                                //     this.moneyData[key].sumFinal = Math.round(this.moneyData[key].rateFinal * this.moneyData[key].quantityFinal / this.moneyData[key].exchangeRate  * sign / 100 * 100)/100;
                                // }
                            } else if (calcRule === 'day') {
                                const days = this.voyageTotals.voyageTime;
                                this.moneyData[key].quantity = days;
                                this.moneyData[key].quantityFinal = days;
                                if (!this.moneyData[key].rate) {
                                    this.moneyData[key].rate = this.resource.dayRate;
                                }
                                if (tools.isNumeric(this.moneyData[key].rate) && tools.isNumeric(this.moneyData[key].quantity)) {
                                    this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.moneyData[key].quantity / this.moneyData[key].exchangeRate * sign * 100) / 100;
                                } else {
                                    this.moneyData[key].sum = 0;
                                }
                                if (!this.moneyData[key].status) {
                                    if (!this.moneyData[key].rateFinal && this.moneyData[key].rate) {
                                        this.moneyData[key].rateFinal = this.moneyData[key].rate;
                                    } else if (this.moneyData[key].rateFinal && !this.moneyData[key].rate) {
                                        this.moneyData[key].rate = this.moneyData[key].rateFinal;
                                    }
                                }
                                if (this.moneyData[key].rateFinal && this.moneyData[key].quantityFinal) {
                                    if (!this.moneyData[key].status) {
                                        this.moneyData[key].sumFinal = Math.round(this.moneyData[key].rateFinal * this.moneyData[key].quantityFinal / this.moneyData[key].exchangeRate * sign * 100) / 100;
                                    }
                                } else if (!this.moneyData[key].status) {
                                    this.moneyData[key].sumFinal = 0;
                                }
                            } else if (this.moneyData[key].type === 'Freight' && tools.isNumeric(this.moneyData[key].bafPercentage) && this.moneyData[key].bafPercentage != 0 && this.moneyData[key].rateBaf) { // BAF calculation for Freight type
                                if (!this.moneyData[key].status) {
                                    if (!this.moneyData[key].rateFinal && this.moneyData[key].rate) {
                                        this.moneyData[key].rateFinal = this.moneyData[key].rate;
                                    } else if (this.moneyData[key].rateFinal && !this.moneyData[key].rate) {
                                        this.moneyData[key].rate = this.moneyData[key].rateFinal;
                                    }
                                }
                                if (tools.isNumeric(this.moneyData[key].rate) && tools.isNumeric(this.moneyData[key].quantity)) {
                                    this.moneyData[key].sum = Math.round((this.moneyData[key].rate + (this.moneyData[key].bafPercentage / 100) * this.moneyData[key].rateBaf) * this.moneyData[key].quantity / this.moneyData[key].exchangeRate * sign * 100) / 100;
                                    // console.log(moneyItem.name, 'sum', this.moneyData[key].sum, this.moneyData[key].rate, this.moneyData[key].bafPercentage, this.moneyData[key].rateBaf, this.moneyData[key].quantity, this.moneyData[key].exchangeRate);
                                } else {
                                    this.moneyData[key].sum = 0;
                                }
                                if (tools.isNumeric(this.moneyData[key].rateFinal) && tools.isNumeric(this.moneyData[key].quantityFinal)) {
                                    if (!this.moneyData[key].status) {
                                        this.moneyData[key].sumFinal = Math.round((this.moneyData[key].rateFinal + (this.moneyData[key].bafPercentage / 100) * this.moneyData[key].rateBaf) * this.moneyData[key].quantityFinal / this.moneyData[key].exchangeRate * sign * 100) / 100;
                                    }
                                } else if (!this.moneyData[key].status) {
                                    this.moneyData[key].sumFinal = 0;
                                }
                            } else {
                                const percentageFactor = this.moneyData[key].unit === '%' ? 0.01 : 1;
                                if (this.moneyData[key].calcRule === 'loadingCost' || this.moneyData[key].calcRule === 'dischargeCost') {
                                    if (!this.moneyData[key].rateFinal) {
                                        this.moneyData[key].rateFinal = this.moneyData[key].status ? this.moneyData[key].rateFinal : this.moneyData[key].rate;
                                    }
                                } else if (!this.moneyData[key].status) {
                                    if (!this.moneyData[key].rateFinal && this.moneyData[key].rate) {
                                        this.moneyData[key].rateFinal = this.moneyData[key].rate;
                                    } else if (this.moneyData[key].rateFinal && !this.moneyData[key].rate) {
                                        this.moneyData[key].rate = this.moneyData[key].rateFinal;
                                    }
                                }
                                if (tools.isNumeric(this.moneyData[key].rate) && tools.isNumeric(this.moneyData[key].quantity)) {
                                    this.moneyData[key].sum = Math.round(this.moneyData[key].rate * this.moneyData[key].quantity / this.moneyData[key]
                                        .exchangeRate * sign * percentageFactor * 100) / 100;
                                } else {
                                    this.moneyData[key].sum = 0;
                                }
                                if (tools.isNumeric(this.moneyData[key].rateFinal) && tools.isNumeric(this.moneyData[key].quantityFinal)) {
                                    if (!this.moneyData[key].status) {
                                        this.moneyData[key].sumFinal = Math.round(this.moneyData[key].rateFinal * this.moneyData[key].quantityFinal / this
                                            .moneyData[key].exchangeRate * percentageFactor * sign * 100) / 100;
                                    }
                                } else if (!this.moneyData[key].status) {
                                    this.moneyData[key].sumFinal = 0;
                                }
                            }
                            // Add tonFinal if unit is ton, mton
                            if (this.moneyData[key].unit === 'ton' && this.moneyData[key].type === 'Freight' && this.moneyData[key].income === 1) {
                                this.moneyData[key].tonFinal = this.moneyData[key].quantityFinal;
                            } else if (this.moneyData[key].unit === 'mton' && this.moneyData[key].type === 'Freight' && this.moneyData[key].income === 1) {
                                this.moneyData[key].tonFinal = this.moneyData[key].quantityFinal * 1000;
                            }
                            // Add fairway count to type = Fairway items
                            if (this.moneyData[key].type === 'Fairway' && fairwayCount !== null) {
                                if (fairwayCount < 10) {
                                    ++fairwayCount;
                                    console.log('Add fairway count ' + fairwayCount);
                                    this.moneyData[key].notes = this.moneyData[key].notes ? `${this.moneyData[key].notes.split('#')[0]} #${fairwayCount}` : `#${ 
                                        fairwayCount}`;
                                } else if (fairwayCount >= 10) {
                                    this.moneyData.splice(key, 1);
                                    ++fairwayCount;
                                    this.alertWarning('Fairway dues item removed, since count is #' + fairwayCount + '. Please save the project.');
                                }
                            } else if (this.moneyData[key].type === 'Fairway' && fairwayCount === null) {
                                console.log('Cannot add fairway count value to notes since count has NULL value');
                            }
                            // Add ETS rate
                            if (this.moneyData[key].rateEts) {
                                // console.log('Add ETS rate: ' + this.moneyData[key].rateEts);
                                this.moneyData[key].sum = this.moneyData[key].sum + Math.round(this.moneyData[key].rateEts * this.moneyData[key].quantity * 100) / 100;
                                if (!this.moneyData[key].status) {
                                    this.moneyData[key].sumFinal = this.moneyData[key].sumFinal + Math.round(this.moneyData[key].rateEts * this.moneyData[key].quantityFinal * 100) / 100;
                                }
                            }
                            // Add Fuel EU
                            if (this.moneyData[key].fuelEU) {
                                // console.log('Add ETS rate: ' + this.moneyData[key].rateEts);
                                this.moneyData[key].sum = this.moneyData[key].sum + Math.round(this.moneyData[key].fuelEU * this.moneyData[key].quantity * 100) / 100;
                                if (!this.moneyData[key].status) {
                                    this.moneyData[key].sumFinal = this.moneyData[key].sumFinal + Math.round(this.moneyData[key].fuelEU * this.moneyData[key].quantityFinal * 100) / 100;
                                }
                            }
                        }
                    }
                    eventBus.$emit('newMoneyData', this.moneyData);
                }
                // eventBus.$emit('updatePortCalls', this.portCalls);
            },
            setBallastTypePortHoursAndSpeed(index) {
                setTimeout(() => {
                    if (this.portCalls[index].type === 8) {
                        this.portCalls[index].portHours = 0;
                        this.portCalls[index].speed = this.resource.ballastSog;
                        this.updateEtdEta(false, index);
                        this.$refs.grid.refresh();
                    }
                }, 10);
            },
            updateEtdEta(lastPortOnly, index = 0) {
                const length = this.portCalls ? this.portCalls.length : 0;
                const startPort = lastPortOnly ? length - 1 : index;
                for (let i = startPort; i < length; i++) {
                    let eta = null;
                    let etd = null;
                    const portHours = typeof this.portCalls[i].portHours !== 'undefined' ? this.portCalls[i].portHours : 24;
                    if (i === 0) {
                        if (this.prevPort === 1 && this.prevPortData && this.portCalls[i].distance && this.portCalls[i].speed) {
                            eta = moment(this.prevPortData.etd).add(this.portCalls[i].distance / this.portCalls[i].speed * 60 * 60, 'seconds');
                            this.portCalls[i].seaText = `${Math.round(this.portCalls[i].distance)}NM/${Math.round(eta.diff(this.prevPortData.etd, 'days', true) * 10) / 10}d`;
                            etd = eta.clone().add(portHours, 'hours');
                        } else {
                            if (this.prevPort === 1 && this.prevPortData && this.portCalls[i].distance === null && this.prevPortData.portId !== this.portCalls[i].portId) {
                                this.portCalls[i].seaText = 'Route not found!';
                            } else {
                                this.portCalls[i].seaText = '';
                            }
                            eta = moment(this.eventRecord.startDate);
                            etd = moment(this.eventRecord.startDate).add(portHours, 'hours');
                        }
                    } else if (this.portCalls[i - 1] && this.portCalls[i - 1].etd) {
                        if (this.portCalls[i].distance !== null && this.portCalls[i].speed >= 0) {
                            eta = moment(this.portCalls[i - 1].etd).add(this.portCalls[i].distance / this.portCalls[i].speed * 60 * 60, 'seconds');
                            etd = eta.clone().add(portHours, 'hours');
                            this.portCalls[i].seaText = `${Math.round(this.portCalls[i].distance)}NM/${Math.round(eta.diff(this.portCalls[i - 1].etd, 'days', true) * 10) / 10}d`;
                        } else {
                            eta = moment(this.portCalls[i - 1].etd).add(1, 'minutes');
                            etd = eta.clone().add(portHours, 'hours');
                            if (this.portCalls[i].distance === null && this.portCalls[i - 1].portId !== this.portCalls[i].portId) {
                                this.portCalls[i].seaText = 'Route not found!';
                            } else {
                                this.portCalls[i].seaText = '';
                            }
                        }
                    }
                    // console.log(eta.format('YYYY-MM-DD HH:mm'), etd.format('YYYY-MM-DD HH:mm'));
                    this.portCalls[i].eta = eta.toDate();
                    this.portCalls[i].etd = etd.toDate();
                    this.portCalls[i].etaDate = eta.format('YYYY-MM-DD');
                    this.portCalls[i].etaTime = eta.format('HH:mm');
                    this.portCalls[i].etdDate = etd.format('YYYY-MM-DD');
                    this.portCalls[i].etdTime = etd.format('HH:mm');
                    this.calcPortHours(i);
                    this.calcVoyageTotals();
                    this.recalculateMoneyData();
                }
            },
            updateDateTime(i) {
                this.portCalls[i].eta = moment(`${this.portCalls[i].etaDate} ${this.portCalls[i].etaTime}`).toDate();
                this.portCalls[i].etd = moment(`${this.portCalls[i].etdDate} ${this.portCalls[i].etdTime}`).toDate();
                this.$refs.grid.refresh();
            },
            initDatesAndTimes(i) {
                if (i) {
                    if (this.portCalls[i].distance !== null) {
                        this.portCalls[i].seaText = `${Math.round(this.portCalls[i].distance)}NM/${Math.round(moment(this.portCalls[i].eta).diff(this.portCalls[i - 1].etd, 'days', true) * 10) / 10}d`;
                    } else if (this.portCalls[i].distance === null && this.portCalls[i - 1].portId !== this.portCalls[i].portId) {
                        this.portCalls[i].seaText = 'Route not found!';
                    } else {
                        this.portCalls[i].seaText = 'Route not found!';
                    }
                    this.portCalls[i].eta = moment(this.portCalls[i].eta).toDate();
                    this.portCalls[i].etd = moment(this.portCalls[i].etd).toDate();
                    this.portCalls[i].etaDate = moment(this.portCalls[i].eta).format('YYYY-MM-DD');
                    this.portCalls[i].etaTime = moment(this.portCalls[i].eta).format('HH:mm');
                    this.portCalls[i].etdDate = moment(this.portCalls[i].etd).format('YYYY-MM-DD');
                    this.portCalls[i].etdTime = moment(this.portCalls[i].etd).format('HH:mm');
                } else {
                    const length = this.portCalls ? this.portCalls.length : 0;
                    for (let i = 0; i < length; i++) {
                        if (i === 0) {
                            if (this.prevPort === 1 && this.prevPortData && this.portCalls[i].distance !== null) {
                                this.portCalls[i].seaText = `${Math.round(this.portCalls[i].distance)}NM/${Math.round(moment(this.portCalls[i].eta).diff(this.prevPortData.etd, 'days', true) * 10) / 10}d`;
                            } else if (this.prevPort === 1 && this.prevPortData && this.portCalls[i].distance === null && this.prevPortData.portId !== this
                                .portCalls[i].portId) {
                                this.portCalls[i].seaText = 'Route not found!';
                            } else {
                                this.portCalls[i].seaText = '';
                            }
                        } else {
                            this.portCalls[i].seaText = `${Math.round(this.portCalls[i].distance)}NM/${Math.round(moment(this.portCalls[i].eta).diff(this.portCalls[i - 1].etd, 'days', true) * 10) / 10}d`;
                        }
                        this.portCalls[i].eta = moment(this.portCalls[i].eta).toDate();
                        this.portCalls[i].etd = moment(this.portCalls[i].etd).toDate();
                        this.portCalls[i].etaDate = moment(this.portCalls[i].eta).format('YYYY-MM-DD');
                        this.portCalls[i].etaTime = moment(this.portCalls[i].eta).format('HH:mm');
                        this.portCalls[i].etdDate = moment(this.portCalls[i].etd).format('YYYY-MM-DD');
                        this.portCalls[i].etdTime = moment(this.portCalls[i].etd).format('HH:mm');
                    }
                }
            },
            rowSelected(args) {
                // console.log('selected', args);
                this.pIndex = typeof args.rowIndex !== 'undefined' ? args.rowIndex : null;
            },
            setPortCallTypeNames() {
                this.portCalls.map((o) => {
                    switch (o.type) {
                        case 1:
                            o.typeName = 'Waiting/Anchoring';
                            break;
                        case 2:
                            o.typeName = 'Loading';
                            break;
                        case 3:
                            o.typeName = 'Discharging';
                            break;
                        case 4:
                            o.typeName = 'Dock/Repairs';
                            break;
                        case 5:
                            o.typeName = 'Off hire';
                            break;
                        case 6:
                            o.typeName = 'Transit';
                            break;
                        case 7:
                            o.typeName = 'Load/Disch';
                            break;
                        case 8:
                            o.typeName = 'Ballast';
                            break;
                        default:
                            o.typeName = 'Not defined';
                            break;
                    }
                    return o;
                });
                this.$refs.grid.refresh();
            },
            clearValues() {
                this.laycan = null;
                this.notes = '';
                this.recap = '';
                this.code = null;
                this.shipIdNominated = null;
                this.blDate = null;
                this.autoCode = 1;
                this.trafficArea = null;
                this.dischCostsOnlyPort = null;
                this.status = null;
                this.portCalls = [];
                this.prevPort = 0;
                this.prevPortData = null;
                this.projectTypeId = null;
                this.isVoyage = null;
                this.pIndex = null;
                this.autoCalcEtdEta = true;
                this.moneyData = [];
                this.voyageTotals = {};
            },
            handlePortHoursInput(pIndex) {
                // if (this.portCalls[pIndex].portHours.length === 6) {
                //     this.calcSpeedEtaEtd(pIndex, 'portHoursToEtd');
                // } else if (this.portCalls[pIndex].portHours.length > 6) {
                //     this.portCalls[pIndex].portHours.length = 6;
                // }
                if (this.portCalls[pIndex].portHours && this.portCalls[pIndex].portHours !== 0) {
                    this.calcSpeedEtaEtd(pIndex, 'portHoursToEtd');
                }
            },
            calcSpeedEtaEtd(index, direction) {
                console.log('calcSpeedEtaEtd', index, direction);
                const length = this.portCalls ? this.portCalls.length : 0;
                if (direction === 'speedToEta') {
                    for (let i = index; i < length; i++) {
                        if (this.portCalls[i].distance && this.portCalls[i].speed > 0) {
                            let eta;
                            if (i === 0 && this.prevPortData && this.prevPort == 1) {
                                eta = moment(this.prevPortData.etd).add(this.portCalls[i].distance / this.portCalls[i]
                                    .speed * 60 * 60, 'seconds');
                            } else {
                                eta = moment(this.portCalls[i - 1].etd).add(this.portCalls[i].distance / this.portCalls[
                                    i].speed * 60 * 60, 'seconds');
                            }
                            const etdDiff = moment(this.portCalls[i].etd).diff(this.portCalls[i].eta);
                            this.portCalls[i].eta = eta.toDate();
                            this.portCalls[i].etd = eta.clone().add(etdDiff, 'milliseconds').toDate();
                            this.initDatesAndTimes(i);
                        }
                    }
                } else if (direction === 'ballastToEta') {
                    for (let i = index; i < length; i++) {
                        if (this.portCalls[i].distance && this.resource.normalSog > 0) {
                            this.portCalls[i].speed = this.resource.normalSog;
                            let eta;
                            if (i === 0 && this.prevPortData && this.prevPort == 1) {
                                eta = moment(this.prevPortData.etd).add(this.portCalls[i].distance / this.portCalls[i].speed * 60 * 60, 'seconds');
                            } else {
                                eta = moment(this.portCalls[i - 1].etd).add(this.portCalls[i].distance / this.portCalls[i].speed * 60 * 60, 'seconds');
                            }
                            const etdDiff = moment(this.portCalls[i].etd).diff(this.portCalls[i].eta);
                            this.portCalls[i].eta = eta.toDate();
                            this.portCalls[i].etd = eta.clone().add(etdDiff, 'milliseconds').toDate();
                            this.initDatesAndTimes(i);
                        }
                    }
                } else if (direction === 'etaToSpeed') {
                    let prevEtd;
                    if (index === 0) {
                        if (this.prevPortData && this.prevPort == 1) {
                            prevEtd = this.prevPortData.etd;
                        }
                    } else {
                        prevEtd = this.portCalls[index - 1].etd;
                    }
                    if (prevEtd && moment(this.portCalls[index].eta).valueOf() < moment(prevEtd).valueOf()) {
                        const eta = moment(prevEtd).add(1, 'minutes').clone();
                        this.portCalls[index].eta = eta.toDate();
                        this.portCalls[index].etaDate = eta.format('YYYY-MM-DD');
                        this.portCalls[index].etaTime = eta.format('HH:mm');
                    }
                    if (this.portCalls[index].distance && this.portCalls[index].eta) {
                        const seaTime = moment(this.portCalls[index].eta).valueOf() - moment(prevEtd).valueOf();
                        this.portCalls[index].speed = Math.round(this.portCalls[index].distance / (seaTime / (60 * 60 *
                            1000)) * 100) / 100;
                    }
                    this.calcPortHours(index);
                    if (this.autoCalcEtdEta === true) { // Update Etd's only if auto calc on checked
                        const etd = moment(this.portTime(this.portCalls[index].portHours)).add(moment(this.portCalls[
                            index].eta).valueOf(), 'milliseconds');
                        this.portCalls[index].etd = etd.toDate();
                        this.portCalls[index].etdDate = etd.format('YYYY-MM-DD');
                        this.portCalls[index].etdTime = etd.format('HH:mm');
                        for (let i = index; i < length - 1; i++) {
                            if (this.portCalls[i + 1].distance && this.portCalls[i + 1].speed > 0) {
                                const eta = moment(this.portCalls[i].etd).clone().add(this.portCalls[i + 1].distance /
                                    this.portCalls[i + 1].speed * 60 * 60, 'seconds');
                                const etdDiff = moment(this.portCalls[i + 1].etd).diff(this.portCalls[i + 1].eta);
                                this.portCalls[i + 1].eta = eta.toDate();
                                this.portCalls[i + 1].etd = eta.clone().add(etdDiff, 'milliseconds').toDate();
                                this.initDatesAndTimes(i + 1);
                            }
                        }
                    }
                } else if (direction === 'etdToEtaEtd') {
                    if (moment(this.portCalls[index].etd).valueOf() < moment(this.portCalls[index].eta).valueOf()) {
                        const etd = moment(this.portCalls[index].eta).add(1, 'minutes').clone();
                        this.portCalls[index].etd = etd.toDate();
                        this.portCalls[index].etdDate = etd.format('YYYY-MM-DD');
                        this.portCalls[index].etdTime = etd.format('HH:mm');
                    }
                    if (this.autoCalcEtdEta === true) {
                        for (let i = index; i < length - 1; i++) {
                            if (this.portCalls[i + 1].distance && this.portCalls[i + 1].speed > 0) {
                                const eta = moment(this.portCalls[i].etd).clone().add(this.portCalls[i + 1].distance / this
                                    .portCalls[i + 1].speed * 60 * 60, 'seconds');
                                const etdDiff = moment(this.portCalls[i + 1].etd).diff(this.portCalls[i + 1].eta);
                                this.portCalls[i + 1].eta = eta.toDate();
                                this.portCalls[i + 1].etd = eta.add(etdDiff, 'milliseconds').toDate();
                                this.initDatesAndTimes(i + 1);
                            }
                        }
                    } else if (this.portCalls[index + 1] && this.portCalls[index + 1].distance && this.portCalls[index + 1].eta) {
                        const seaTime = moment(this.portCalls[index + 1].eta).valueOf() - moment(this.portCalls[index].etd).valueOf();
                        this.portCalls[index + 1].speed = Math.round(this.portCalls[index + 1].distance / (seaTime / (60 * 60 * 1000)) * 100) / 100;
                    }
                } else if (direction === 'portHoursToEtd') {
                    if (this.portCalls[index].eta && this.portCalls[index].portHours) {
                        const etd = moment(this.portCalls[index].eta).add(this.portTime(this.portCalls[index].portHours),
                            'milliseconds');
                        this.portCalls[index].etd = etd.toDate();
                        this.portCalls[index].etdDate = etd.format('YYYY-MM-DD');
                        this.portCalls[index].etdTime = etd.format('HH:mm');
                        this.etdKey++; // update key to force value update, since not updated otherwise
                        let prevEtd = etd.clone();
                        for (let i = index; i < length - 1; i++) {
                            if (this.portCalls[i + 1].distance && this.portCalls[i + 1].speed > 0) {
                                const eta = prevEtd.clone().add(this.portCalls[i + 1].distance / this.portCalls[i + 1].speed * 60 * 60, 'seconds');
                                // console.log(prevEtd.format('MM.DD HH:mm'), eta.format('MM.DD HH:mm'), this.portCalls[i + 1].distance, this.portCalls[i + 1].speed )
                                const etdDiff = moment(this.portCalls[i + 1].etd).diff(this.portCalls[i + 1].eta);
                                this.portCalls[i + 1].eta = eta.toDate();
                                this.portCalls[i + 1].etd = eta.add(etdDiff, 'milliseconds').toDate();
                                prevEtd = moment(this.portCalls[i + 1].etd);
                                this.initDatesAndTimes(i + 1);
                            }
                        }
                    }
                }
                // Update BL date
                if (this.autoCode === 1) {
                    const firstLoading = this.portCalls.find((o) => o.type === 2 || o.type === 7);
                    if (firstLoading && firstLoading.etdDate) {
                        this.blDate = firstLoading.etdDate;
                    }
                }

                this.$refs.grid.refresh();
                this.calcVoyageTotals();
                this.recalculateMoneyData();
            },
            portTime(portHours) {
                let portTime = 24 * 60 * 60 * 1000;
                // if (portHours.indexOf(":") !== -1) {
                //     portTime = parseFloat(portHours.split(':')[0]) * 60 * 60 * 1000 + parseFloat(portHours.split(':')[
                //         1]) * 60 * 1000;
                // }
                if (portHours) {
                    portTime = Math.round(portHours * 60 * 60 * 1000);
                }
                return portTime;
            },
            calcPortHours(index) {
                if (this.portCalls[index] && this.portCalls[index].etd && this.portCalls[index].eta) {
                    const portTime = moment(this.portCalls[index].etd).diff(this.portCalls[index].eta, 'hours', true);
                    this.portCalls[index].portHours = Math.round(portTime * 100) / 100;
                    // let hours = Math.floor(portTime / (60 * 60 * 1000));
                    // let mins = Math.floor((portTime - hours * 60 * 60 * 1000) / (60 * 1000));
                    // if (hours < 100) {
                    //     hours = '0' + hours;
                    // }
                    // if (mins < 10) {
                    //     mins = '0' + mins;
                    // }
                    // this.portCalls[index].portHours = hours + ':' + mins;
                }
            },
            // setOpen(value) { // is editor window open
            //     this.open = value;
            // },
            showModifiedTime() {
                return this.eventRecord ? moment(this.eventRecord.modified).fromNow() : '';
            },
            checkPrevPort() {
                if (this.prevPort === 1) {
                    // Find previous port to voyage
                    if (this.events && this.events.length > 0) {
                        const voyagesFound = [];
                        let prevVoyage = null;
                        for (const row of this.events) {
                            if (this.resource.id === row.resourceId && row.isVoyage === 1 && moment(row.endDate).valueOf() <= moment(this.eventRecord
                                    .startDate).valueOf()) {
                                voyagesFound.push(row);
                            }
                        }
                        if (voyagesFound.length > 1) {
                            voyagesFound.sort((a, b) => ((moment(a.endDate).valueOf() > moment(b.endDate).valueOf()) ? 1 : ((moment(a.endDate).valueOf() <
                                moment(b.endDate).valueOf()) ? -1 : 0)));
                            // console.log(voyagesFound.map((o) => o.lastPort + ' ' + o.endDate));
                            prevVoyage = voyagesFound[voyagesFound.length - 1];
                        } else if (voyagesFound.length === 1) {
                            prevVoyage = voyagesFound[0];
                        } else {
                            setTimeout(() => {
                                this.prevPort = 0;
                            }, 10);
                            this.prevPortData = null;
                            this.alertError(
                                'Previous port can\'t be set since this ship has no recent previous voyages.'
                            );
                        }
                        // get port calls if previous voyage found
                        if (prevVoyage && prevVoyage.id) {
                            this.fetchPortCalls(prevVoyage.id).then((data) => {
                                if (data && data.portCalls && data.portCalls.length > 0) {
                                    this.prevPortData = data.portCalls[data.portCalls.length - 1];
                                    // console.log('prevPortData', this.prevPortData);
                                    this.fetchSchedulerRoutes({
                                        data: this.portCalls,
                                        prevPortData: this.prevPortData
                                    }).then((data) => {
                                        this.portCalls = [];
                                        setTimeout(() => {
                                            this.portCalls = data;
                                            this.portCallIds = this.portCalls.map((o) => o.portId);
                                            this.updateEtdEta(false);
                                            this.$refs.grid.refresh();
                                        }, 10);
                                    });
                                } else {
                                    setTimeout(() => {
                                        this.prevPort = 0;
                                    }, 10);
                                    this.prevPortData = null;
                                    this.alertError(
                                        'Previous port can\'t be found since this ship has no recent previous voyages.'
                                    );
                                }
                            });
                        }
                    }
                } else {
                    this.prevPortData = null;
                    this.fetchSchedulerRoutes({
                        data: this.portCalls,
                        prevPortData: this.prevPortData
                    }).then((data) => {
                        this.portCalls = data;
                        // Set first port routeId to NULL
                        this.portCalls[0].routeId = null;
                        this.portCallIds = this.portCalls.map((o) => o.portId);
                        this.updateEtdEta(false);
                        this.$refs.grid.refresh();
                    });
                }
            },
            reCalculateRoutes() {
                this.fetchSchedulerRoutes({
                    data: this.portCalls,
                    prevPortData: this.prevPortData
                }).then((data) => {
                    this.portCalls = data;
                    this.portCallIds = this.portCalls.map((o) => o.portId);
                    this.updateEtdEta(false);
                    this.$refs.grid.refresh();
                });
            },
            calcVoyageTotals() {
                const length = this.portCalls.length;
                if (length > 0) {
                    let voyageTime = 0;
                    let portTime = 0;
                    let seaTime = 0;
                    let distance = 0;
                    let cons = 0;
                    let cons2 = 0;
                    const ship = this.getShips().find((o) => o.id === this.resource.shipId);
                    const ballastCons = ship && ship.ballastCons ? ship.ballastCons : 0.021;
                    const normalCons = ship && ship.normalCons ? ship.normalCons : 0.021;
                    const secondaryBallastCons = ship && ship.secondaryBallastCons ? ship.secondaryBallastCons : 0;
                    const secondaryCons = ship && ship.secondaryCons ? ship.secondaryCons : 0;
                    const portCons = ship && ship.portCons ? ship.portCons : 0.25;

                    let consSteaming = 0;
                    let secondaryConsSteaming = 0;
                    let consPort = 0;

                    for (let i = 0; i < length; i++) {
                        if (i === 0) {
                            if (this.prevPort === 1 && this.prevPortData && this.prevPortData.etd) {
                                seaTime += moment(this.portCalls[i].eta).diff(this.prevPortData.etd, 'days', true);
                                if (this.portCalls[i].distance) {
                                    consSteaming += this.portCalls[i].distance * ballastCons;
                                    secondaryConsSteaming += this.portCalls[i].distance * secondaryBallastCons;
                                }
                            }
                        } else {
                            seaTime += moment(this.portCalls[i].eta).diff(this.portCalls[i - 1].etd, 'days', true);
                            if (this.portCalls[i].distance) {
                                consSteaming += this.portCalls[i].distance * normalCons;
                                secondaryConsSteaming += this.portCalls[i].distance * secondaryCons;
                            }
                        }
                        const days = moment(this.portCalls[i].etd).diff(this.portCalls[i].eta, 'days', true);
                        portTime += days;
                        consPort += days * portCons;
                        if (this.portCalls[i].distance) {
                            distance += this.portCalls[i].distance;
                        }
                    }
                    cons = consSteaming + consPort;
                    cons2 = secondaryConsSteaming + consPort;
                    voyageTime = seaTime + portTime;
                    // console.log(voyageTime, seaTime, portTime, cons)
                    this.voyageTotals = {
                        voyageTime: Math.round(voyageTime * 1000) / 1000,
                        portTime: Math.round(portTime * 1000) / 1000,
                        seaTime: Math.round(seaTime * 1000) / 1000,
                        distance: Math.round(distance * 100) / 100,
                        cons: Math.round(cons * 100) / 100,
                        cons2: Math.round(cons2 * 100) / 100,
                        consSteaming: Math.round(consSteaming * 100) / 100,
                        secondaryConsSteaming: Math.round(secondaryConsSteaming * 100) / 100,
                        consPort: Math.round(consPort * 100) / 100,
                        text: `${Math.round(voyageTime * 10) / 10} d / ${Math.round(cons * 10) / 10} tons (${Math.round(distance * 10) / 10 
                            } NM / ${Math.round((seaTime) * 10) / 10} d)`,
                    };
                    eventBus.$emit('updateVoygeTotals', this.voyageTotals);
                }
            },
            onFocusDateRangePicker(args) {
                args.model.show();
            },
            cancelProjectEdit() {
                console.log(this.eventRecord.isCreating, this.eventRecord.isPersistable)
                // if (this.eventRecord.isCreating === false) {
                    // this.eventRecord.remove();
                // } else {
                    this.updateProject({
                        data: [{
                            id: this.id,
                            cancel: 1
                        }]
                    });
                // } 
                //  clear values
                this.clearValues();
                this.$emit('input', false);
                this.$emit('close');
            },
            callUnlockProject() {
                this.unlockProject({
                    id: this.id
                }).then((data) => {
                    if (data && data.success) {
                        this.lastEdit = null;
                        this.lastEditName = null;
                        this.lastEditorId = null;
                    } else {
                        this.alertError('Failed to unlock project');
                    }
                });
            },
            showLockText() {
                if (this.lastEdit && this.lastEditName) {
                    return `Project was locked by ${this.lastEditName} ${this.lastEdit.fromNow()}. Click icon to force unlock project.`;
                } else {
                    return '';
                }
            },
            callDeleteProject() {
                this.alertConfirm({
                    message: 'Are you sure you want to delete project permanently?',
                    emit: 'deleteProject'
                });
            },
            onRouteViewerClose() {
                console.log('onRouteViewerClose');
                // this.showRouteViewer = false;
            },
            callUploadFiles() {
                if (this.files && this.files.length > 0) {
                    let params = {
                        projectId: this.id
                    };
                    params.files = [];
                    for (let i = 0; i < this.files.length; i++) {
                        const file = this.files[i];
                        // console.log(file);
                        if (file.size > 50 * 1024 * 1024) {
                            this.alertError('Max file size is 50MB');
                            return;
                        }
                        if (!this.filenames[i].name) {
                            this.alertError('File name must be defined');
                            return;
                        }
                        if (this.filenames[i].name.length > 80) {
                            this.alertError('Max file name is 80 characters');
                            return;
                        }
                        if (!this.filenames[i].ext) {
                            this.alertError('File extension must be defined');
                            return;
                        }
                        params.files[i] = {
                            name: this.filenames[i].name + '.' + this.filenames[i].ext,
                            size: file.size,
                            type: file.type,
                            lastModified: file.lastModified
                        };
                    }
                    this.dialog = false;
                    this.getPresignPostS3(params).then((data) => {
                        // console.log(data);
                        let uploadStatus = 0;
                        for (let i = 0; i < data.length; i++) {
                            // Build a form for the request body
                            let form = new FormData();
                            Object.keys(data[i].data.fields).forEach(key => form.append(key, data[i].data.fields[key]));
                            form.append('file', this.files[i]);
                            params.files[i].s3Key = data[i].id;
                            // Upload to S3
                            let url = data[i].data.url;
                            this.uploadFileS3({
                                url,
                                form
                            }).then(() => {
                                console.log('File ' + this.files[i].name + ' upload to S3 success');
                                ++uploadStatus;
                                if (uploadStatus === this.files.length) {
                                    this.uploadFileSuccessS3(params).then((data) => {
                                        this.projectFiles = this.convertFileSize(data);
                                        this.files.length = 0;
                                        this.alertSuccess(uploadStatus + ' files uploaded to s3');
                                    })
                                }
                            });
                        }
                    });
                    /* OLD WAY
                    const formData = new FormData();
                    formData.append('projectId', this.id);
                    for (let i = 0; i < this.files.length; i++) {
                        const file = this.files[i];
                        // console.log(file);
                        if (file.size > 4 * 1024 * 1024) {
                            this.alertError('Max file size is 4MB');
                            return;
                        }
                        formData.append(`files[${i}]`, file);
                    }
                    this.uploadFiles(formData).then((data) => {
                        this.projectFiles = this.convertFileSize(data);
                        this.files.length = 0;
                    });*/
                } else {
                    this.alertError('Select files to upload first!');
                }
            },
            callDownloadFile(file) {
                // console.log(file);
                if (file && file.id) {
                    if (file.s3Key) {
                        this.getPresignUrlS3(file.id).then((data) => {
                            this.downloadFileS3(data.url).then((response) => {
                                // console.log(response);
                                tools.saveFile(response.data, response.headers, file.name);
                            });
                        });
                    } else {
                        this.downloadFile(file.id).then((response) => {
                            // console.log(response);
                            tools.saveFile(response.data, response.headers, null);
                        });
                    }
                }
            },
            callDeleteFile(file) {
                if (file && file.id) {
                    this.deleteFileId = file.id;
                    this.alertConfirm({
                        message: `Are you sure you want to delete '${file.name}' permanently?`,
                        emit: 'deleteFile'
                    });

                }
            },
            convertFileSize(data) {
                if (data) {
                    return data.map((o) => {
                        if (o.size > 1048576) {
                            o.size = `${Math.round(o.size / 1048576 * 10) / 10}MB`;
                        } else if (o.size > 1024) {
                            o.size = `${Math.round(o.size / 1024 * 10) / 10}kB`;
                        } else {
                            o.size += 'B';
                        }
                        return o;
                    });
                }
            },
            formatTime(time) {
                return moment(time).format('D.M.YYYY HH:mm');
            },
            formatName(name) {
                if (name.length > 10) {
                    return `${name.substr(0, 10)}..`;
                }
                return name;
            },
            portsTableHeight() {
                let length = 3 * 29;
                if (Array.isArray(this.portCalls) && this.portCalls.length >= 6) {
                    length = 6 * 29;
                } else if (Array.isArray(this.portCalls) && this.portCalls.length > 3) {
                    length = 29 * this.portCalls.length;
                }
                return length;
            },
            getExchangeRate(currency) {
                if (this.getUser.user.baseCurrency && currency && this.getUser.user.baseCurrency !== currency) {
                    return typeof this.exchangeRates[currency] !== 'undefined' ? this.exchangeRates[currency] : 1;
                }
                return 1;
            },
            addDropFile(e) {
                this.files.push(...Array.from(e.dataTransfer.files));
                this.showFileDialog();
            },
            showFileDialog() {
                if (this.files && this.files.length > 0) {
                    this.filenames = this.files.map((o) => {
                        let x = o.name.split('.');
                        let name = '';
                        for (let i = 0, il = x.length; i < il; i++) {
                            if (i < il - 1) {
                                name += x[i] + '.';
                            }
                        }
                        name = name.substr(0, name.length - 1);
                        let ext = x[x.length - 1];
                        return {
                            name: name,
                            ext: ext
                        };
                    });

                    this.dialog = true;
                }
            },
            commandClick(args) {
                if (args.target.classList.contains("custombutton")) { // Copy clipboard button
                    let shipName = this.resource.name;
                    let cc = null;
                    let to = '';
                    if (this.shipIdNominated) {
                        const nominated = this.extShips.find((o) => o.id === this.shipIdNominated);
                        shipName = nominated.name;
                        cc = nominated.emails ? nominated.emails : null;
                        console.log('Nominated ship: ' + shipName + ' id: ' + this.shipIdNominated);
                    } else if (this.resource.shipId) {
                        const ship = this.getShips().find((o) => o.id === this.resource.shipId);
                        cc = ship.emails ? ship.emails : null;
                    }
                    let portsTitle = '';
                    let ports = [];
                    let portCallIds = [];
                    let etaList = '';
                    let prevPort = this.prevPort === 1 && this.prevPortData ? this.prevPortData.name : null;
                    for (let i = 0, il = this.portCalls.length; i < il; i++) {
                        if (moment(this.portCalls[i].etd).unix() > moment().unix()) { // Exclude if ETD is in the past
                            ports.push(this.portCalls[i]);
                            portCallIds.push(this.portCalls[i].id);
                            portsTitle += this.portCalls[i].portCallName + ' - ';
                            let prevText = ports.length === 1 && prevPort ? ', arriving from ' + prevPort : '';
                            etaList += `- ETA ${this.portCalls[i].portCallName} ${moment(this.portCalls[i].eta).format('ddd D/M A')}${prevText}%0A`;
                            if (this.portCalls[i].id === args.rowData.id) {
                                break; // Clicked port is last to copy
                            }
                        } else if (i > 0) {
                            prevPort = this.portCalls[i].portCallName;
                        }
                    }
                    // console.log(ports);
                    // Find money data cost items from selected port calls
                    let moneyFound = this.moneyData.filter((o) => portCallIds.findIndex((i) => i === o.portCallId && o.income !== 1) !== -1);
                    // Find contact emails
                    let contactEmails = moneyFound.filter((o) => o.contactEmails).map((o) => o.contactEmails);
                    contactEmails = [...new Set(contactEmails)]; // Unique emails only
                    if (contactEmails.length > 0) {
                        to = contactEmails.join(', ');
                        console.log('Found following contact emails for ETA update port call cost item: ', contactEmails);
                    } else {
                        console.log('No contact email available from any ETA update port calls cost items.');
                        this.alertWarning('No contact email available from any ETA update port calls cost items. Please update your contact data.');
                    }
                    let subject = `${shipName} / ${portsTitle.substr(0, portsTitle.length - 3)}`;
                    let body = `Good day,%0A%0A
Please be informed of following schedule update for ${shipName}:%0A
${etaList}%0A
All times AGW WP.%0A%0A
Keeping you posted on further updates.`;
                    // navigator.clipboard.writeText(text);
                    // this.alertSuccess('ETA update copied to clipboard.');
                    body = tools.replaceAll(body, '&', encodeURIComponent('&'));
                    subject = tools.replaceAll(subject, '&', encodeURIComponent('&'));
                    if (!cc || (cc && cc.length < 3)) {
                        location = 'mailto:' + to + '?subject=' + subject + '&body=' + body;
                    } else {
                        location = 'mailto:' + to + '?cc=' + cc + '&subject=' + subject + '&body=' + body;
                    }
                }
            },
            checkCode(value) {
                let res = true;
                if (this.getUser && this.getUser.user.projectCodeType == 2) {
                    if (value) {
                        if (value.length !== 10) {
                            res = false;
                        } else if (!value.toString().startsWith('244')) {
                            res = false;
                        } else if (value.toString().substr(3, 2) !== this.trafficArea.toString()) {
                            res = false;
                        }
                        return res;
                    } else {
                        return false;
                    }
                }
                return res;
            },
            changeTraffic() {
                const code = this.code;
                this.code = '';
                setTimeout(() => {
                    this.code = code;
                }, 10);
            }
        },
        computed: {
            ...mapGetters({
                getUser: 'authentication/getUser',
                exchangeRates: 'data/getExchangeRates',
                ships: 'data/getShips',
                features: 'authentication/features',
                dark: 'users/getDarkMode',
            }),
            extShips() {
                const extShips = this.getShips().filter((o) => o.external === 1);
                return [{
                    id: null,
                    name: 'None'
                }].concat(extShips);
            },
            saveDisabled() {
                if (this.disableSave) {
                    return true;
                } else if (!this.startDate && !this.endDate) {
                    return true;
                } else if (this.isVoyage === 1 && (!this.portCalls || this.portCalls.length === 0)) {
                    return true;
                } else if (this.lastEditorId !== null && this.lastEditorId !== this.getUser.user.id) {
                    return true;
                } else {
                    return false;
                }
            },
            firstDayOfWeek() {
                return this.getFirstDayOfWeek();
            },
            activeProjectTypes() {
                return this.params.projectTypes.filter((o) => o.active === 1);
            },
        },
        provide: {
            grid: [RowDD, Selection, CommandColumn]
        }
    };
</script>

<style scoped>
    .v-input.v-textarea.v-text-field {
        margin: 0;
        padding: 0;
    }
</style>

<style>
    #voyageSettings .v-messages {
        position: absolute;
        margin-top: -20px;
    }

    .v-dialog {
        max-width: 1800px;
        margin: 15px;
        max-height: 95% !important;
    }

    .light-text {
        color: white !important;
    }

    .saturday {
        color: #f39c12 !important;
        font-weight: 500;
    }

    .sunday {
        color: #f44336 !important;
        font-weight: 500;
    }

    .route-error {
        background-color: rgb(244, 67, 54, 0.2) !important;
        font-weight: 500;
    }

    .e-grid.e-default .e-headercell,
    .e-grid.e-default .e-detailheadercell {
        height: 30px;
    }

    .file-list .v-chip {
        padding: 5px !important;
        /* min-width: 95px; */
    }

    .file-list .v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
        position: absolute;
        right: 5px;
    }

    .v-textarea textarea {
        font-size: 14px;
        line-height: 18px;
    }

    .custombutton {
        font-size: 14px !important;
        font-weight: normal !important;
        margin: 0px !important;
        padding: 0px !important;
    }

    @media only screen and (max-width: 600px) {
        .v-dialog {
            margin: 5px;
            max-height: 99% !important;
        }

        .v-btn--icon {
            width: auto !important;
        }

        .hide-mobile {
            display: none !important;
        }
    }
</style>