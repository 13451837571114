<template>
    <div>     
        <div style="height: calc(100vh - 145px)">
            <ejs-grid ref="grid" id="grid" :dataSource="data" 
                :allowResizing="true"
                :toolbar="toolbar"
                :allowReordering="true"
                :allowExcelExport="true"
                :toolbarClick="toolbarClick" 
                :allowPaging="true"
                :allowSorting="true"
                :allowFiltering="true" 
                :allowGrouping="true"
                :filterSettings="filterOptions"
                :sortSettings="sortOptions"
                :groupSettings="groupOptions" 
                :pageSettings="pageSettings"
                :showColumnChooser="true" 
                :dataStateChange="dataStateChange" 
                :dataSourceChanged="dataSourceChanged" 
                :actionComplete="actionHandler"
                :excelQueryCellInfo="excelQueryCellInfo"
                height="100%">
                <e-columns>
                    <e-column field="id" headerText="ID" :visible="isVisible('id')" :allowGrouping="false" :allowFiltering="false" width="70" :isPrimaryKey="true"></e-column>
                    <e-column field="projectLocked" headerText="" :visible="isVisible('projectLocked')" :allowGrouping="false" :allowFiltering="false" :valueAccessor="valueAccessor" width="25"></e-column>
                    <e-column field="projectTypeId" headerText="Project type" :visible="isVisible('projectTypeId')" :allowGrouping="true" :filter="filter" :valueAccessor="valueAccessor" width="110"></e-column>
                    <e-column field="trafficArea" headerText="Traffic area" :visible="isVisible('trafficArea')" :filter="filter" :allowGrouping="true" width="108"></e-column>
                    <e-column field="resourceId" headerText="Resource" :visible="isVisible('resourceId')" :filter="filter" :allowGrouping="true" :valueAccessor="valueAccessor" width="110"></e-column>
                    <e-column field="shipName" headerText="Ship" :visible="isVisible('shipId')" :filter="filter" :allowFiltering="true" :allowGrouping="true" width="90"></e-column>
                    <e-column field="code" headerText="Project code" :visible="isVisible('code')" :filter="filter" :allowGrouping="true" :allowSorting="true" width="115"></e-column>
                    <e-column field="yearmonth" headerText="Month" :visible="isVisible('yearmonth')" :filter="filter" width="80"></e-column>
                    <e-column field="startDate" headerText="Date" :visible="isVisible('startDate')" format="d.M" type="date" :allowGrouping="false" width="75"></e-column>
                    <e-column field="blDate" headerText="BL date" :visible="isVisible('blDate')" format="d.M" type="date" :allowGrouping="false" width="90"></e-column>
                    <e-column field="blMonth" headerText="BL month" :visible="isVisible('blMonth')" :filter="filter" width="97"></e-column>
                    <e-column field="startEtd" headerText="ETD date" :visible="isVisible('etdDate')" format="d.M" type="date" :allowGrouping="false" width="95"></e-column>
                    <e-column field="startEtdMonth" headerText="ETD month" :visible="isVisible('etdMonth')" :filter="filter" width="105"></e-column>
                    <e-column field="startEtdYear" headerText="ETD year" :visible="isVisible('etdYear')" :filter="filter" width="95"></e-column>
                    <e-column field="ports" headerText="Ports" :visible="isVisible('ports')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" width="170"></e-column>
                    <e-column field="cargo" headerText="Cargo" :visible="isVisible('cargo')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" width="130"></e-column>
                    <e-column field="exportTons" headerText="Export tons" :visible="isVisible('exportTons')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="importTons" headerText="Import tons" :visible="isVisible('importTons')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="cabotageTons" headerText="Cabotage tons" :visible="isVisible('cabotageTons')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="crosstradeTons" headerText="Crosstrade tons" :visible="isVisible('crosstradeTons')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="totalTons" headerText="Total tons" :visible="isVisible('totalTons')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="90"></e-column>
                    <e-column field="avgUnitPrice" headerText="Avg unit price" :visible="isVisible('avgUnitPrice')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="final" headerText="Final" :visible="isVisible('final')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="budget" headerText="Budget" :visible="isVisible('budget')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="opsResult" headerText="Ops result" :visible="isVisible('opsResult')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="incomeTotal" headerText="Income total" :visible="isVisible('incomeTotal')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="costTotal" headerText="Cost total" :visible="isVisible('costTotal')" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" textAlign="Right" width="100"></e-column>
                    <e-column field="contactId" headerText="Customer" :visible="isVisible('contactId')" :filter="filter" :allowGrouping="false" :valueAccessor="valueAccessor" width="130"></e-column>
                    <e-column field="complete" headerText="Complete" headerTextAlign="Center" type="string" :visible="isVisible('complete')" :filter="filter" :valueAccessor="valueAccessor" textAlign="Center" width="100"></e-column>
                    <e-column field="costComplete" headerText="Cost complete" headerTextAlign="Center" type="string" :visible="isVisible('costComplete')" :filter="filter" :valueAccessor="valueAccessor" textAlign="Center" width="110"></e-column>
                    <e-column field="incomeComplete" headerText="Income complete" headerTextAlign="Center" type="string" :visible="isVisible('incomeComplete')" :filter="filter" :valueAccessor="valueAccessor" textAlign="Center" width="110"></e-column>
                    <e-column field="status" headerText="Status" :visible="isVisible('status')" :filter="filter" :allowGrouping="true" :valueAccessor="valueAccessor" width="80"></e-column>
                </e-columns>
                <e-aggregates>
                    <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="exportTons" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="importTons" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="cabotageTons" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="crosstradeTons" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="totalTons" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="budget" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="final" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="opsResult" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="incomeTotal" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="costTotal" :groupCaptionTemplate="footerSum" headerTextAlign="Center"></e-column>
                    </e-columns>
                </e-aggregate>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="exportTons" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="importTons" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="cabotageTons" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="crosstradeTons" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="totalTons" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="budget" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="final" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="opsResult" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="incomeTotal" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="costTotal" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
            </ejs-grid>
        </div>
        
        <!-- Components -->
        <ProgressCircular /> 
    </div>
</template>

<script>
/* eslint-disable */ 
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import moment from 'moment';
    import { GridPlugin, Group, Page, Sort, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter, Aggregate } from "@syncfusion/ej2-vue-grids";
    import { loadCldr, setCulture, createElement} from '@syncfusion/ej2-base';
    import { MultiSelect, CheckBoxSelection, DropDownList } from "@syncfusion/ej2-dropdowns";
    import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
    MultiSelect.Inject(CheckBoxSelection);
    
    import SumAggregateNoText from "./SumAggregateNoText.vue";
    import CaptionTemplate from "./CaptionTemplate.vue";
    import ProgressCircular from '../ProgressCircular.vue';
    import GridDataService from '../../services/SchedulerGridData.service'; 
    import { eventBus } from '../../main';
    
    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 
    
    
    Vue.use(MultiSelectPlugin);
    Vue.use(GridPlugin);

    export default {
        name: 'SchedulerTable',
        props: {
            events: Array,
            resources: Array,
            params: Object,
        },
        components: {
            ProgressCircular
        },
        data() {
            let dropInstance = {};
            return {
                data: [],
                state: null,
                pageSettings: {pageSizes: [10,25,50,100,200,500,1000,'All'], pageSize: 200},
                groupOptions: {captionTemplate:  function () {
                    return  { template : CaptionTemplate }
                }},
                sortOptions: {columns: [{ field: 'startDate', direction: 'Descending' }]},
                toolbar: [
                    { text: 'Edit', tooltipText: 'Edit project', prefixIcon: 'edit', id: 'edit' },
                    { text: 'Refresh', tooltipText: 'Refresh table', prefixIcon: 'refresh', id: 'refresh' },
                    { text: 'Expand', tooltipText: 'Expand all', prefixIcon: 'expand', id: 'expand' },
                    { text: 'Collapse', tooltipText: 'Collapse all', prefixIcon: 'collapse', id: 'collapse' },
                    'Print', 'ColumnChooser', 'ExcelExport'],
                intParams: {params: {decimals: 3, format: 'n'}},
                decimal2Params: {params: {decimals: 2}},
                dataService: new GridDataService(),
                ddParams: { params: { dataSource: []} },
                footerSum: function () {
                    return  { template : SumAggregateNoText }
                },
                filterOptions: {type: 'Menu'},
                filter: {
                    // type: 'Checkbox',
                    operator: 'equal',
                    ui: {
                        create: function (args) {
                            let flValInput = createElement('input', { className: 'flm-input' });
                            args.target.appendChild(flValInput);
                            let field = args.column.field;
                            console.log(field);
                            if (field === 'resourceId') {
                                let fields = {text: 'name', value: 'id'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/resources/read').then((data) => {
                                    dropInstance = new MultiSelect({
                                        dataSource: data.data,
                                        fields: fields,
                                        placeholder: 'Select resource',
                                        popupHeight: '200px',
                                        allowFiltering: true,
                                        mode: 'CheckBox',
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'code') {
                                let fields = {text: 'name', value: 'value'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/code').then((data) => {
                                    dropInstance = new MultiSelect({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select project code',
                                        popupHeight: '200px',
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'yearmonth') {
                                let fields = {text: 'name', value: 'value'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/yearmonth').then((data) => {
                                    dropInstance = new MultiSelect({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select month/year',
                                        popupHeight: '200px',
                                        allowFiltering: true,
                                        mode: 'CheckBox',
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'blMonth') {
                                let fields = {text: 'name', value: 'value'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/blmonth').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select BL month/year',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'startEtdMonth') {
                                dropInstance = new MultiSelect({
                                    dataSource: [
                                        {value: 1, text: 'January'},
                                        {value: 2, text: 'February'},
                                        {value: 3, text: 'March'},
                                        {value: 4, text: 'April'},
                                        {value: 5, text: 'May'},
                                        {value: 6, text: 'June'},
                                        {value: 7, text: 'July'},
                                        {value: 8, text: 'August'},
                                        {value: 9, text: 'September'},
                                        {value: 10, text: 'October'},
                                        {value: 11, text: 'November'},
                                        {value: 12, text: 'December'},
                                    ],
                                    fields: {text: 'text', value: 'value'},
                                    placeholder: 'Select ETD month',
                                    popupHeight: '200px',
                                    allowFiltering: true,
                                    mode: 'CheckBox',
                                });
                                dropInstance.appendTo(flValInput);
                            } else if (field === 'startEtdYear') {
                                let fields = {text: 'name', value: 'value'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/startetdyear').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select ETD year',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'contactId') {
                                let fields = {text: 'name', value: 'contactId'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/contact').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select customer',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'shipName') {
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/ships').then((data) => {
                                    dropInstance = new MultiSelect({
                                        dataSource: data,
                                        fields: {text: 'text', value: 'value'},
                                        placeholder: 'Select ship',
                                        popupHeight: '200px',
                                        allowFiltering: true,
                                        mode: 'CheckBox',
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'projectTypeId') {
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/projecttype').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields: {text: 'name', value: 'projectTypeId'},
                                        placeholder: 'Select project type',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'status') {
                                dropInstance = new DropDownList({
                                    dataSource: [{text: 'Planned', value: 0}, {text: 'Nominated', value: 1}, {text: 'Confirmed', value: 2}, {text: 'Cloned', value: 3}],
                                    fields: {text: 'text', value: 'value'},
                                    placeholder: 'Select status',
                                    popupHeight: '200px'
                                });
                                dropInstance.appendTo(flValInput);
                            } else if (field === 'trafficArea') {
                                dropInstance = new DropDownList({
                                        dataSource: [
                                            {value: '21', text: '21 France & UK'},
                                            {value: '35', text: '35 PRS pulp CE & WE'},
                                            {value: '36', text: '36 Mussalo pulp CE & WE'},
                                            {value: '43', text: '43 Rauma, Rostock'},
                                            {value: '54', text: '54 KTK, RAU, FRO, SDR, AMS'},
                                            {value: '64', text: '64 USA'},
                                            {value: '91', text: '91 Raw materials'},
                                            {value: '88', text: '88 Overseas china clay'},
                                            {value: '89', text: '89 Europe china clay'},
                                            {value: '95', text: '95 Other imports'},
                                            {value: '38', text: '38 External'},
                                        ],
                                        fields: {text: 'text', value: 'value'},
                                        placeholder: 'Select traffic area',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                            } else if (field === 'complete' || field === 'costComplete' || field === 'incomeComplete') {
                                dropInstance = new DropDownList({
                                        dataSource: [
                                            // {value: '0', text: '0-9%'},
                                            // {value: '10', text: '10-19%'},
                                            // {value: '20', text: '20-29%'},
                                            // {value: '30', text: '30-39%'},
                                            // {value: '40', text: '40-49%'},
                                            // {value: '50', text: '50-59%'},
                                            // {value: '60', text: '60-69%'},
                                            // {value: '70', text: '70-79%'},
                                            // {value: '80', text: '80-89%'},
                                            // {value: '90', text: '90-99%'},
                                            // {value: '100', text: '100%'},
                                            // {value: '100', text: '100%'},
                                            {value: 0, text: 'No'},
                                            {value: 1, text: 'Yes'},
                                        ],
                                        fields: {text: 'text', value: 'value'},
                                        placeholder: 'Select complete status',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                            }
                        },
                        write: function (args) {
                            var grid = document.getElementById("grid").ej2_instances[0];
                            var filteredValue = [];
                            grid.filterSettings.columns.map((item) => {
                                if (item.field === "resourceId" && item.value) {
                                    filteredValue.push(item.value);
                                }
                            });
                            if (filteredValue.length > 0) {
                                dropInstance.value = filteredValue;
                            }
                        },
                        read: function (args) {
                            var grid = document.getElementById("grid").ej2_instances[0];
                            grid.removeFilteredColsByField(args.column.field);
                            args.fltrObj.filterByColumn(
                                args.column.field,
                                "contains",
                                dropInstance.value
                            );
                        }
                    }
                }
            }
        },
        mounted() {
            setCulture('en-FI');
            let state = { skip: 0, take: 200 };
            this.dataStateChange(state);
            if (this.getShips().length === 0) {
                this.fetchShips().then((data) => {this.ddParams.params.dataSource = data});
            } else {
                this.ddParams.params.dataSource = this.getShips();
            }
            eventBus.$on('reloadDataTable', () => {
                // this.dataStateChange(this.state);
                this.$refs.grid.refresh();
            });
            setTimeout(() => {
                this.$refs.grid.ej2Instances.toolbarModule.enableItems(['collapse','expand'],false);
            }, 300);
        },
        computed: {
            ...mapGetters({
                user: 'authentication/getUser',
                features: 'authentication/features',
            }),
        },
        beforeDestroy() {
            eventBus.$off('reloadDataTable');
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchResource: 'data/fetchResource',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
            }),
            valueAccessor(field, data) {
                if (field === 'resourceId') {
                    return data.resource;
                } else if (field === 'contactId') {
                    return data.contactName;
                } else if (field === 'projectTypeId') {
                    return data.projectType;
                } else if (field === 'status') {
                    return data.statusName;
                } else if (field === 'projectLocked') {
                    return data.projectLocked === '1' ? '🔒' : '';
                } else if (field === 'complete') {
                    return data.complete ? 'Yes' : data.complete === 0 ? 'No' : '';
                } else if (field === 'costComplete') {
                    return data.costComplete ? 'Yes' : data.costComplete === 0 ? 'No' : '';
                } else if (field === 'incomeComplete') {
                    return data.incomeComplete ? 'Yes' : data.incomeComplete === 0 ? 'No' : '';
                }
            },
            queryCellInfo(args) { 
               if (args.column.field === 'income') { 
                   
                args.cell.innerHTML = args.data.income === 1 ? 'Income' : 'Cost';
               }
            },
            dataStateChange(state) {
                if (state && state.action && state.action.requestType === 'filterchoicerequest') {
                    // state.action.filterModel.values['Not completed ', 'Auto OK'];
                } else { // Get data from backend
                    this.dataService.get(state).then((gridData) => this.data = gridData);
                }
                this.state = state;
            },
            dataSourceChanged(state) {
                if (state.action === 'add' && state.requestType === 'save') {
                   
                } else if (state.action === 'edit' && state.requestType === 'save') {
                   
                } else if (state.requestType === 'delete') {
                   
                }
            },
            actionHandler(args) {
                if (args.requestType === 'grouping') {
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['collapse','expand'],true);
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['refresh'],false);
                } else if (args.requestType === 'ungrouping') {
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['refresh'],true);
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['collapse','expand'],false);
                }
            },
            async toolbarClick(args) {
                if (args.item.id.includes('excelexport')) {
                    // let records = this.$refs.grid.dataSource.result;
                    // let length = records.length;
                    // console.log(records);
                    // for (let i = 0; i < length; i++) {
                    //     records[i].startDate = records[i].startDate ? moment(records[i].startDate).format('D.M') : '';
                    // }
                    let exportProperties = {
                        // dataSource: records,
                        fileName: "desk_report.xlsx"
                    };
                    // Fix for column grouping aggregate style error
                    const excelAggregateStyle = ExcelExport.prototype.aggregateStyle
                    ExcelExport.prototype.aggregateStyle = function () {
                        try {
                            excelAggregateStyle.apply(this, arguments)
                        } catch (error) {
                            console.warn(`Error in ExcelExport.prototype.aggregateStyle: ${error}`, error)
                        }
                    }
                    // Export excel
                    this.$refs.grid.excelExport(exportProperties);
                } else if (args.item.id === 'refresh') {
                    this.dataStateChange(this.state);
                    this.$refs.grid.refresh();
                } else if (args.item.id === 'expand') {
                    this.$refs.grid.ej2Instances.groupModule.expandAll();
                } else if (args.item.id === 'collapse') {
                    this.$refs.grid.ej2Instances.groupModule.collapseAll();
                } else if (args.item.id === 'edit') {
                    let selectedRecords = this.$refs.grid.getSelectedRecords();  
                    if (selectedRecords && selectedRecords[0] && selectedRecords[0].id) {
                        let resource = null;
                        if (this.resources && this.resources.length > 0) {
                            resource = this.resources.find((o) => {
                                return o.id === selectedRecords[0].resourceId;
                            })
                            if (!resource) {
                                console.log('Resource not found. Fetching from server.')
                                resource = await this.fetchResource(selectedRecords[0].resourceId)
                                if (!resource) {
                                    this.$store.dispatch('alert/error', 'Resource is not active and not found on database.', {root: true});  
                                    return;  
                                } else {
                                    console.log('Resource record found', resource);
                                }
                            }
                            let event = selectedRecords[0];
                            event.data = selectedRecords[0];
                            // console.log('tableEdit', event);
                            eventBus.$emit('tableEdit', ({
                                event: event,
                                events: this.events,
                                resource: resource,
                            }));
                        } else {
                            this.$store.dispatch('alert/error', 'Resources are not available.', {root: true});    
                        }
                    } else {
                        this.$store.dispatch('alert/error', 'Select project/voyage first.', {root: true});
                    }
                }
            },
            isVisible(param) {
                if (typeof this.features.schedulerTable === 'object' && this.features.schedulerTable !== null && 
                    typeof this.features.schedulerTable[param] !== 'undefined' && this.features.schedulerTable[param] === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            excelQueryCellInfo(args) { 
                if (args.column.field === 'blDate' || args.column.field === 'startDate' ||args.column.field === 'startEtd') { 
                    args.value = moment(args.value).isValid() ? moment(args.value).toDate() : args.value;
                } else if (args.column.field === 'startEtdMonth' || args.column.field === 'blMonth' || args.column.field === 'yearmonth') { 
                    args.value = args.value ? parseInt(args.value.split('/')[0]) : '';
                } else if (args.column.field === 'startEtdYear') { 
                    args.value = args.value ? parseInt(args.value) : '';
                }
            },
        },
        provide: {
            grid: [Group, Page, Sort, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter, Aggregate ]
        }
    }

</script>

<style>
    /* Hide filter operator dropdown */
    .e-flm_optrdiv {
        display: none;
    }
    .e-grid .e-flmenu-valuediv {
        padding: 4px 0 0;
    }
    .refresh::before {
      content: '\e606';
    }
    .edit::before {
      content: '\e7a3';
    }
    .expand::before {
      content: '\e556';
    }
    .collapse::before {
      content: '\e554';
    }
</style>