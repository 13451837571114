import { point, polygon } from '@turf/helpers';
import rhumbDestination from '@turf/rhumb-destination';
import moment from 'moment';
import { tools } from '.';

const invariant = require('@turf/invariant');

export const shipHelpers = {
    headingOrCog,
    getNavstat,
    shipIcon, 
    shipGeojson,
    shipTooltipOptions,
    toRad,
    vectorSvg,
    shipPopup,
    calculateCargo,
};

/**
 * @description Show heading
 *
 * @param {int} heading Ship heading
 * @param {*} cog Ship COG (Course over ground)
 * @param {boolean} returnCog Return value cog is preferred if true
 * @returns {int} Ship direction
 */
function headingOrCog(heading, cog, returnCog = false) {
    if (parseInt(heading, 10) === 511 || heading === null || (returnCog && cog)) {
        return cog;
    } 
        return heading;
}

/**
 * @description Converts degrees to radians
 *
 * @param {float} value Angle in degrees
 * @returns {float} Angle in redians
 */
function toRad(value) {
    return value * Math.PI / 180;
}

/**
 * @description Ship speed predictor
 *
 * @param {float} sog Speed over ground
 * @param {string} color Color of the arrow
 * @returns {object} SVG arrow preditor image and height of the arrow
 */
function vectorSvg(sog, color) {
    if (sog < 50) {
        let height = 1;
        if (sog > 1) {
            height = 15 + (sog * 1.4);
        } 
        const image = `data:image/svg+xml,${escape(`<svg xmlns="http://www.w3.org/2000/svg" width="8.232" height="${height}" viewBox="0 0 7.7173041 ${height}"><g fill="${color}" fill-rule="evenodd" stroke="${color}"><path d="M3.91 ${height}V1.77M.433 6.636L4.085.31M7.284 6.576L3.632.25"/></g></svg>`)}`; 
        return { image, height };
    } 
        return null;
}

/**
 * @description AIS navigation status
 *
 * @param {int} navstat Ais navigation status
 * @param {boolean} short Short version
 * @returns {object} Navigation status text and color
 */
function getNavstat(navstat, short = false){
    navstat = navstat ? navstat : 0;
    if (short) {
        switch (parseInt(navstat, 10)) {
            case 0:
                return { status: 'Under way', color: 'blue' };
            case 1:
                return { status: 'At anchor', color: 'brown' };
            case 2:
                return { status: 'NUC', color: 'error' };
            case 3:
                return { status: 'Rest. man.', color: 'warning' };
            case 4:
                return { status: 'Const. by draught', color: 'warning' };
            case 5:
                return { status: 'Moored', color: 'blue-grey' };
            case 6:
                return { status: 'Aground', color: 'error' };
            case 7:
                return { status: 'Fishing', color: 'blue' };
            case 8:
                return { status: 'Sailing', color: 'blue' };
            case 9:
                return { status: 'High speed', color: 'blue' };
            case 11:
                return { status: 'Towing astern', color: 'blue' };
            case 12:
                return { status: 'Pushing ahead or towing alongside', color: 'blue' };
            default:
                return { status: '', color: 'blue-grey' };
        }
    } else {
        switch (parseInt(navstat, 10)) {
            case 0:
                return { status: 'Under way using engine', color: 'blue' };
            case 1:
                return { status: 'At anchor', color: 'brown' };
            case 2:
                return { status: 'Not under command', color: 'error' };
            case 3:
                return { status: 'Restricted maneuverability', color: 'warning' };
            case 4:
                return { status: 'Constrained by her draught', color: 'warning' };
            case 5:
                return { status: 'Moored', color: 'blue-grey' };
            case 6:
                return { status: 'Aground', color: 'error' };
            case 7:
                return { status: 'Engaged in fishing', color: 'blue' };
            case 8:
                return { status: 'Under way sailing', color: 'blue' };
            case 9:
                return { status: 'High speed craft', color: 'blue' };
            case 11:
                return { status: 'Power-driven vessel towing astern', color: 'blue' };
            case 12:
                return { status: 'Power-driven vessel pushing ahead or towing alongside', color: 'blue' };
            default:
                return { status: '', color: 'blue-grey' }; // return {status: 'Not available', color:'blue-grey'};
        }
    }
}

/**
 * @description Get ship icon based on ais navigation status
 *
 * @param {int} navstat Ais navigation status
 * @param {string} [pre='os'] Prefix, os = own ship
 * @param {int} mmsi Ship mmsi
 * @param {int} shipType Ship type
 * @param {float} sog Ship sog
 * @returns {string} Ship icon url
 */
function shipIcon(navstat, pre = 'os', mmsi, shipType = null, sog = null) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.user && user.user.source === 1) {
        return `${pre}_blue.png`;
    }
    if (!isNaN(navstat)) {
        navstat = parseInt(navstat, 10);
    }
    if (!isNaN(shipType)) {
        shipType = parseInt(shipType, 10);
    }
    if (mmsi) {
        if (mmsi.toString().substring(0, 2) === '99') {
            return 'aton.png';
        } if (mmsi.toString().substring(0, 2) === '00') {
            return 'base.png';
        } if (shipType === 30) {
            return 'fishing.png';
        } if (shipType === 50) {
            return 'pilot.png';
        } if (shipType === 51) {
            return 'sar.png';
        }
    }
    if (navstat === 2 || navstat === 6) {
        return `${pre}_red.png`;
    } if (navstat === 0 || navstat === 7 || navstat === 8 || navstat === 9 || navstat === 11 || navstat === 12) {
        return `${pre}_blue.png`;
    } if (navstat === 1) {
        return `${pre}_brown.png`;
    } if (navstat === 5) {
        return `${pre}_grey.png`;
    } if (navstat === 3 || navstat === 4) {
        return `${pre}_yellow.png`;       
    } 
    if (typeof sog !== 'undefined' && sog !== null) {
        if (sog > 0) {
            return `${pre}_blue.png`;
        } 
            return `${pre}_grey.png`;
    } 
        return `${pre}_white.png`;
}

/**
 * @description Ship map tooltip 
 *
 * @param {object} ais Ship AIS data
 * @param {object} L Leaflet object
 * @returns {string} Ship map tooltip
 */
function shipTooltipOptions(ais, L) {
    const tooltipOptions = {
        permanent: true,
        direction: 'top',
        offset: L.point(0, -10),
        opacity: 0.8,
    };
    const now = moment.utc().valueOf();
    const updated = moment.utc(ais.time).valueOf();
    if (now - updated <= 2 * 60 * 60 * 1000){
        tooltipOptions.className = 'tooltip-valid';
    } else if (now - updated <= 12 * 60 * 60 * 1000){
        tooltipOptions.className = 'tooltip-warn';
    } else {
        tooltipOptions.className = 'tooltip-error';
    }
    return tooltipOptions;
}

/**
 * @description Converts ais data to ship outline coordinates
 *
 * @param {obejct} ais Ais data
 * @returns {string} Ship outline in geojson format
 */
function shipGeojson(ais) {
    if (ais.latitude && ais.longitude) {
        // Set default dimension if not set
        ais.a = ais.a ? ais.a / 1000 : 30 / 1000;
        ais.b = ais.b ? ais.b / 1000 : 100 / 1000;
        ais.c = ais.c ? ais.c / 1000 : 15 / 1000;
        ais.d = ais.d ? ais.d / 1000 : 15 / 1000;
        const aisPoint = point([ais.longitude, ais.latitude]);
        let heading;
        if (parseInt(ais.heading, 10) === 511 || ais.heading === null) {
            heading = parseInt(ais.cog, 10);
        } else {
            heading = parseInt(ais.heading, 10);
        }
        
        let bearingD = heading + 90;
        if (bearingD > 360) {
            bearingD -= 360;
        }
        let bearingC = heading - 90;
        if (bearingC < 0) {
            bearingC += 360;
        }
        const bearingA = heading;
        let bearingB = heading - 180;
        if (bearingB < 0) {
            bearingB += 360;
        }
        const pointD = rhumbDestination(aisPoint, ais.d, bearingD);
        const pointC = rhumbDestination(aisPoint, ais.c, bearingC);

        const pointAD = rhumbDestination(pointD, ais.a, bearingA);
        const pointBD = rhumbDestination(pointD, ais.b, bearingB);

        // let pointAC = rhumbDestination(pointC, ais.a, bearingA);
        const pointBC = rhumbDestination(pointC, ais.b, bearingB);

        const halfWidth = (ais.c + ais.d) / 2;
        const hypotenuse = halfWidth * Math.sqrt(2);
        let bearingAD2 = heading + 90 + 45;
        if (bearingAD2 > 360) {
            bearingAD2 -= 360;
        }
        let bearingAC2 = heading - 90 - 45;
        if (bearingAC2 < 0) {
            bearingAC2 += 360;
        }
        const pointA = rhumbDestination(pointAD, halfWidth, bearingC);
        const pointAD2 = rhumbDestination(pointA, hypotenuse, bearingAD2);
        const pointAC2 = rhumbDestination(pointA, hypotenuse, bearingAC2);

        const geojson = polygon([[
            invariant.getCoord(pointA),
            invariant.getCoord(pointAD2),
            invariant.getCoord(pointBD),
            invariant.getCoord(pointBC),
            invariant.getCoord(pointAC2),
            invariant.getCoord(pointA),
            ]], { name: ais.shipname ? ais.shipname : '' });
        return geojson;
    } 
    return null;
}

/**
 * @description Ship map AIS popup
 *
 * @param {object} ais Ais data
 * @param {boolean} relativeTime Is timestamp displayed in relative time
 * @returns {string} Ship ais popup html
 */
function shipPopup(ais, relativeTime = true) {
    ais.navstat = ais.navstat ? ais.navstat : ais.navStatus;
    ais.name = ais.name ? ais.name : ais.shipname;
    ais.name = ais.name ? ais.name : ais.Ship;
    let popup = `<b>${ais.name}</b><br/>`;
    if (typeof ais.sog !== 'undefined' && ais.sog !== null) {
        popup += `SOG: ${Math.round(ais.sog * 10) / 10} kn<br/>`;
    }
    if (ais.draught) {
        popup += `Draught: ${Math.round(ais.draught * 10) / 10} m<br/>`;
    }
    if (ais.navstat) {
        popup += `Status: <span class="${shipHelpers.getNavstat(ais.navstat).color}--text">${shipHelpers.getNavstat(ais.navstat).status}</span><br/>`;
    }
    if (ais.eta) {
        popup += `AIS ETA: ${moment.utc(ais.eta).format('D.M HH:mm')} UTC<br/>`;
    }
    if (ais.destination) {
        popup += `AIS Dest: ${tools.escapeHtml(ais.destination)}<br/>`;
    }
    if (ais.imo) {
        popup += `IMO: ${ais.imo}<br/>`;
    }
    if (ais.mmsi) {
        popup += `MMSI: ${ais.mmsi}<br/>`;
    }
    if (ais.time) {
        if (relativeTime) {
            popup += `Last seen: ${tools.timeAgo(ais.time)}<br/>`;
        } else {
            popup += `Last seen: ${moment.utc(ais.time).format('D.M HH:mm')}<br/>`;
        }
    }
    return popup;
}

/**
 * @description Calculate cargo values
 *
 * @param {object} data Cargo data
 * @returns {object} Ton, cuf, ton %, cuf %
 */
function calculateCargo(data) {
    let ton = 0;
    let cuf = 0;
    let tonPercent = 0;
    let cufPercent = 0; 
    if (data.unit === 'ton' || data.unit === 'dton' || data.unit === 'mton') {
        ton = Math.round(data.quantity);
        if (data.sf && data.sf !== '0.0') {
            cuf = Math.round(data.quantity * data.sf);
        }
    } else if (data.unit === 'm3' || data.unit === 'cuf') {
        cuf = Math.round(data.quantity);
        if (data.unit === 'm3') {
            cuf = Math.round(35.31 * data.quantity);
        }
        if (data.sf && data.sf !== '0.0') {
            ton = Math.round(cuf / data.sf);
        }
    }
    if (data.dwcc) {
        tonPercent = Number((ton / data.dwcc * 100).toFixed(1));
    }
    if (data.cuf) {
        cufPercent = Number((cuf / data.cuf * 100).toFixed(1));
    }
    return {
        ton,
        cuf,
        tonPercent,
        cufPercent,
    };
}
